@import "../../../../index.scss";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerSpacing {
  margin-bottom: 1.4286rem;
}

.content {
  display: flex;
  min-height: 0;
}

.relatedContent {
  flex-basis: 24%;
  padding-left: 1.65rem;
  padding-top: 1.5rem;
  border-top: 1px solid $bsi-color-light-dark;
}

.restricted {
  margin: auto;
}

@media (max-width: 1079px) {
  .content {
    display: block;
    height: inherit;
    overflow: auto;
  }

  .relatedContent {
    padding-left: 0;
    border-top: 1px solid $bsi-color-dark-lighter;
  }
}
