@import "../../../../index.scss";

.root {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .placeholder {
    margin-top: 3.6rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-icon {
      font-size: 1.4rem;
      color: $bsi-color-dark-light;
    }
    &-text {
      white-space: pre-line;
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.5;
      color: $bsi-color-dark-light;
    }
  }
}
