@import "../../../../index.scss";

.root {
  display: flex;
  padding: 2rem 0 0 2rem;
  height: 100%;
  flex-direction: column;
}

.hidden {
  display: none;
}

.footer {
  padding: 1.2857rem 2.1429rem;
  box-shadow: 3px 0 6px 0 rgba($bsi-black, 0.16);
  border-top: solid 1px $bsi-color-light-darker;
  width: 120%;
  background-color: $bsi-white;
  margin: auto 0 0 -2rem;
}

.eventViewer {
  overflow-y: auto;
}

@media (max-width: 809px) {
  .root {
    padding-right: 0;
    margin: 0;
  }

  .footer {
    padding-left: 2.5rem;
    margin: auto 0 0 -3.5rem;
  }
}
