@import "../../../../../index.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;
}

.title {
  font-family: $bsi-gotham-s-sm;
  font-size: 1.1429rem;
  color: $bsi-color-dark-base;
  margin: 0;
  padding: 1rem 0;
}

.icons {
  flex: 1;
  text-align: right;
  margin-right: -1rem;
}

.icons > :global(i.large.icon) {
  font-size: 2rem;
  margin-left: 1rem;
}

i:global(.icon).rightIcon {
  margin-left: 3px;
}

@media (max-width: 809px) {
  .icons {
    margin-right: -1.1429rem;
  }
}
