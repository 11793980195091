@import "../../../../index.scss";

.root {
  flex: 1;
  height: calc(100vh - $page-headers-height);
  overflow-y: overlay;
  margin-right: $scroll-width;
}

.hidden {
  display: none;
}

.detailsWrapper {
  padding-left: 2rem;
}

@media (max-width: 809px) {
  .root {
    height: calc(100vh - $page-headers-height - 12px);
  }

  .detailsWrapper {
    padding-left: unset;
  }
}
