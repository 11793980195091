@import "../../../../../index.scss";

.root {
  height: 92%;
  bottom: 52%;
  border-left: solid $progress-line-width $bsi-color-accent-green-base;
  position: absolute;
  left: calc(0.6rem - $progress-line-width / 2);
  z-index: -1;
}

.root.isFirst {
  display: none;
}

.root.disabled {
  border-left: solid 2px $bsi-color-light-darker;
}
