.root {
  display: flex;
  height: 100%;
}

.hidden {
  display: none;
}

@media (max-width: 809px) {
  .root {
    padding-right: 0;
  }
}
