@import "../../../../index.scss";

.root {
  display: flex;
  width: 75%;
}

.picture {
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.circularImage {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.clearPhoto {
  position: absolute;
  top: 0;
  right: 0;
}

.fields {
  flex: 1;
  margin-left: 5.7143rem;
  margin-top: 0.3571rem;
  align-self: stretch;
  min-width: 0;
}

.label {
  font-size: 1.1429rem;
  font-family: $bsi-gotham-s-sm;
  margin-bottom: 0.5714rem;
}

.fileUpload {
  font-weight: bold;
  cursor: pointer;
  color: $bsi-color-primary-blue-base;
  text-align: center;
  margin-top: 1.4286rem;
  position: relative;
}

.fileUpload:hover {
  color: $bsi-color-primary-blue-darker;
}

input[type="file"] {
  display: none;
}

.uploadText {
  position: relative;
  font-size: 1.1429rem;
  top: 2px;
}

.inputWrapper {
  margin-bottom: 1.7143rem;
}

.jobTitleSpacing {
  margin-top: 2.7857rem;
}

.fakeInput {
  display: flex;
  position: relative;
  color: $bsi-color-dark-lighter;
  padding: 0.67857143em 1em;
  border: 1px solid rgba($bsi-color-dark-base, 0.15);
  border-radius: 0.28571429rem;
  text-align: left;
  height: 1.4286rem;
}

.fakeInputInner {
  position: relative;
  top: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .root {
    width: 91%;
  }

  .circularImage {
    width: 152px;
    height: 152px;
  }

  .fileUpload {
    margin-top: 2.1429rem;
  }

  .fields {
    margin-left: 2.1429rem;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .root {
    flex-direction: column;
    align-items: center;
    width: 77%;
    margin: 0 auto;
  }

  .fields {
    margin-top: 3.2143rem;
    margin-left: 0;
  }

  .fileUpload {
    margin-top: 1.9286rem;
  }
}

@media (max-width: 375px) {
  .root {
    width: auto;
  }

  .fileUpload {
    margin-top: 1.4286rem;
  }

  .fields {
    margin-top: 3.4286rem;
  }
}
