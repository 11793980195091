.root {
  display: flex;
}

.cardContainer {
  padding-right: 24px;
  font-size: 0.9rem;
  width: 293px;
}

.hidden {
  visibility: hidden;
}
