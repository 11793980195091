@import "../../../../index.scss";

.root {
  padding: 1.4286rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.4286rem;
  border-bottom: 1px solid $bsi-color-light-dark;
  margin-bottom: 1.4286rem;
}

.title {
  font-family: $bsi-gt-walsheim;
  font-size: 1.8571rem;
  margin: 0;
  padding: 0;
}

.icon {
  font-size: 1.4286rem;
  color: $bsi-color-dark-light;
}

.content {
  margin-bottom: 3.8571rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

/* Trying to align with regular <Button> styles */
.cancelButton {
  padding: 0.5714rem 0;
  width: 9.4286rem;
  background-color: $bsi-white;
  color: $bsi-color-primary-blue-base;
  border: 2px solid $bsi-color-primary-blue-base;
  border-radius: $button-border-radius;
  white-space: nowrap;
}

.cancelButton:hover {
  cursor: pointer;
  background-color: $bsi-color-light-dark;
}

.confirmContainer {
  margin-left: 0.8571rem;
}

/* Lifting styles from Button.module.scss */
.confirmButton {
  background-color: $bsi-color-primary-blue-base;
  border: none;
  color: $bsi-white;
  font-weight: bold;
  width: 9.4286rem;
  padding: 0.7143rem 0;
  border-radius: $button-border-radius;
  cursor: pointer;
  white-space: nowrap;
}

.confirmButton:hover {
  background-color: $bsi-color-primary-blue-darker;
}
