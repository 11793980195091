@import "../../../../../index.scss";

$gap: 1rem;

.root {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: $gap;
}

.meta {
  min-width: calc(40% - $gap / 2);
  display: flex;
  align-items: flex-end;
  color: rgba($bsi-color-dark-base, 0.6);
  font-size: 0.8571rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div {
    width: -webkit-fill-available;

    & > div {
      display: grid;

      & > div {
        // Needed to override inline styles
        display: block !important;
      }
    }
  }
}

.extra {
  min-width: calc(60% - $gap / 2);
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.stacked {
  flex-direction: column;
}

.light {
  color: rgba($bsi-color-light-base, 0.8);
}

.iconContainer {
  margin: 0 1.5rem;
}

.placeholder {
  width: 100%;
}

@media (max-width: 1220px) {
  .root {
    flex-direction: column;
  }
}
