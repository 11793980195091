@import "../../../../index.scss";

.root .actionIcons i {
  margin: 0.7143rem;
  font-size: 1.4286rem;
  color: $bsi-color-dark-light;
  cursor: pointer;
}

.root .actionIcons i:hover {
  color: $bsi-color-dark-base;
}

.actionIcons {
  position: absolute;
  top: -4.1429rem;
  right: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0 1.25rem 1.4286rem;
  border-bottom: solid 1px $bsi-color-light-dark;
}

.header .title {
  margin: auto 0;
  font-family: $bsi-gotham-s-sm;
  font-size: 1.4286rem;
  font-weight: 900;
  color: $bsi-color-dark-base;
}

.info {
  color: $bsi-color-dark-base;
  font-size: 1.1429rem;
  font-weight: 500;
  line-height: 1.88;
  margin: auto 0;
  flex-shrink: 0;
}

@media (max-width: 809px) {
  .actionIcons {
    position: relative;
    top: auto;
    margin-left: -0.7143rem;
  }

  .header {
    padding: 0 0 0.5rem 0;
  }
}
