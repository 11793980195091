@import "../../../../index.scss";

.root {
  font-size: 1.1429rem;
  display: flex;
  cursor: pointer;
}

.back {
  color: $bsi-color-dark-base;
  line-height: 1.7143rem;
  margin-left: 0.3571rem;
}

@media (min-width: 809px) {
  .root {
    padding: 0.3571rem;
  }
}
