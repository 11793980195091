@import "../../../../index.scss";

$fade-out-width: 11.75rem;

.root {
  overflow: hidden;
  box-sizing: border-box;
}

.pageWindow {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.pageWindow .fadeOutGradient {
  position: absolute;
  z-index: 10;
  right: 0;
  height: 100%;
  width: $fade-out-width;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 40%,
    rgba(255, 255, 255, 1) 75%
  );
  opacity: 1;
}

.lastPageActive .fadeOutGradient {
  opacity: 0;
  transition: opacity $medium ease-out;
}

.carouselPage {
  position: absolute;
  top: 0;
  left: 0;
}

.activePage {
  opacity: 1;
  transition: left $medium ease-in-out, opacity 1s ease-out;
}

@media (max-width: 1079px) and (min-width: 810px) {
  .root {
    padding-left: 5px;
  }
}
