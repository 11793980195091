@import "../../../../../index.scss";

.root {
  margin-left: auto;
  display: flex;
}

:global(i.icon.fitted).bagTriggerIcon {
  margin-left: 10px !important;
  font-size: 1.429rem;
}

.bagTriggerIcon {
  &.selected {
    color: $bsi-color-primary-blue-base;
  }
}

.bagTriggerCustomIcon {
  margin-left: 0.625rem;
  height: 1.429rem;
  width: 1.429rem;
  fill: $bsi-color-dark-light;

  &.selected {
    fill: $bsi-color-primary-blue-base;
  }
}
