@import "../../../../../index.scss";

.priorityLevelWithIcon {
  min-width: 8.2rem;
  height: 1.4285rem;

  font-size: 0.6428rem;
  font-weight: bold;
  text-align: center;
  line-height: normal;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  border: 1px solid $bsi-black;
  border-radius: 3px;

  padding: 0.2rem 0.8rem 0.2rem 0rem;
}

.requiredIconWrapper {
  width: 0.8571rem;
  height: 0.8571rem;
  border: 1px solid $bsi-color-accent-red-base;
  font-size: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.priorityLevel {
  min-width: 6.2857rem;
  height: 1.4285rem;

  font-size: 0.6428rem;
  font-weight: bold;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid $bsi-black;
  border-radius: 3px;
}

.required {
  color: $bsi-color-accent-red-base;
  border-color: $bsi-color-accent-red-base;
}

.requiredTextWrapper {
  margin-right: 0.8571rem;
}

.important {
  color: $bsi-color-accent-green-base;
  border-color: $bsi-color-accent-green-base;
}

.normal {
  color: $bsi-color-primary-blue-base;
  border-color: $bsi-color-primary-blue-base;
}

.dueDate {
  font-size: 0.6429rem;
  font-weight: bold;
  color: $bsi-color-accent-red-base;
  padding: 0.1429rem 0.5rem;
  margin-right: 0.2143rem;
  border-radius: 3px;
  border: solid 1px $bsi-color-accent-red-base;
  line-height: normal;
}
