@import "../../../../../index.scss";

.sessionInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}

.sessionDateTimeContainer {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 1.3;
}

.sessionDateTime {
  color: $bsi-color-dark-base;
  margin: auto 0.4rem;
}

.sessionDateTime.expired {
  color: $bsi-color-accent-red-base;
}
.sessionDateTime.expired > b {
  margin-right: 0.5rem;
}

.sessionRegistrationActions {
  margin-right: 1rem;
}

.sessionRegistrationInfo {
  color: $bsi-color-dark-light;
  margin-right: 1rem;
}

.sessionRegistrationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: 1079px) {
  .sessionDateTime {
    white-space: pre-line;
  }
}

@media (max-width: 809px) {
  .sessionInfoContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .sessionRegistrationContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .sessionDateTime {
    font-weight: 500;
  }
}
