.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.6429rem;
  position: relative;
}

.headerText {
  margin: 0;
}

@media (max-width: 809px) {
  .header {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .headerText {
    font-size: 24px;
  }
}
