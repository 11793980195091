@import "../../../../index.scss";

.footer {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $footer-height;
  box-shadow: 0 -1px 6px $bsi-color-light-darker;
  background-color: $bsi-white;
}

.footerContents {
  margin: 0 2.4286rem;
}

.cancelButton,
.saveButton {
  box-sizing: border-box;
  width: 335px;
  font-size: 1.1429rem;
  padding: 0.6429rem 0px;
  font-weight: bold;
  border-radius: $button-border-radius;
  border: 2px solid $bsi-color-primary-blue-base;
}

.cancelButton:hover,
.saveButton:hover {
  cursor: pointer;
}

.cancelButton:disabled,
.saveButton:disabled {
  opacity: 0.3;
  cursor: auto;
}

.cancelButton {
  color: $bsi-color-primary-blue-base;
  background-color: $bsi-white;
}

.saveButton {
  margin-left: 1.4286rem;
  color: $bsi-white;
  background-color: $bsi-color-primary-blue-base;
}

.saveButton:hover {
  background-color: $bsi-color-primary-blue-darker;
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .footerContents {
    margin: 0 1.7857rem;
  }

  .cancelButton,
  .saveButton {
    width: 221px;
  }

  .saveButton {
    margin-left: 2.1429rem;
  }
}

/* sm breakpoint */
@media (max-width: 809px) {
  .footerContents {
    margin: 0 0.5714rem;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  .cancelButton,
  .saveButton {
    flex-basis: 50%;
  }
}
