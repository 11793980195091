@import "../../../../index.scss";

.root {
  margin: auto;
  max-width: 26.5rem;

  .icon {
    font-size: 10rem;
    color: $bsi-color-light-dark;
    margin-bottom: 1.5rem;
  }

  .button {
    width: 17.25rem;
  }
}
