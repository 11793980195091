@import "../../../../../index.scss";

.root {
  font-size: 0.835rem;

  &.green {
    :global(i.icon) {
      color: $bsi-color-accent-green-base !important;
    }
  }

  &.gray {
    :global(i.icon) {
      color: $bsi-color-dark-lighter !important;
    }
  }

  &.yellow {
    :global(i.icon) {
      color: $bsi-color-accent-yellow-base !important;
    }
  }
}
