@import "../../../../../index.scss";

.sessionRegistration {
  display: inline-flex;
}

.buttonLabels {
  margin: 0.2em;
}

.unRegisterButtonLabel {
  margin: 0.057rem;
}

.actionButton {
  width: 11rem;
  display: flex;
  justify-content: flex-end;
}

.loader {
  height: 21.69px;
  box-sizing: content-box;
}

.unRegisterLoader {
  height: 17.68px;
  box-sizing: border-box;
}

@media (max-width: 809px) {
  .actionButton {
    justify-content: flex-start;
  }
}
