@import "../../../../index.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 1.1429rem;
  color: $bsi-color-dark-base;
}

.sortByContainer {
  flex: 1;
  text-align: right;
  font-size: 1.1429rem;
}

.sortByLabel {
  padding-right: 0.5714rem;
}

.body {
  padding-top: 2.5714rem;
}

.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hidden {
  display: none;
}

@media (max-width: 809px) {
  .body {
    padding-top: 0;
  }

  .header {
    margin-bottom: 1.5rem;
  }
}
