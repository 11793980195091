@import "../../../../index.scss";

.root {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  .pushed {
    margin-top: $banner-height;
  }
}
