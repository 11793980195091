@import "../../../../index.scss";

.mobileSortbySection {
  font-size: 1.1429rem;
}

.headerIconContainer {
  flex: 1;
  text-align: right;
  margin-right: 2px;
}

.iconColor {
  color: $bsi-color-dark-light;
}

.pushed {
  margin-right: 0.3571rem;
}

i:global(.icon).heavy {
  font-weight: bold;
}

.sortByHeader {
  display: flex;
  padding: 1rem;
  padding-left: 1.7143rem;
  align-items: center;
}

.option {
  display: flex;
  background-color: $bsi-white;
  color: $bsi-color-dark-base;
  padding: 1rem 0.8571rem 1rem 1.7143rem;
  align-items: center;
}

.option:hover {
  cursor: pointer;

  &:not(.selected) {
    background-color: $bsi-color-light-dark;
  }
}

.selected {
  background-color: $bsi-color-light-dark;
  font-weight: bold;
}

.iconContainer {
  flex: 1;
  text-align: right;
  color: $bsi-color-dark-light;
}
