@import "../../../../index.scss";

.root {
  display: flex;
  align-items: center;
  height: 4.2857rem;
  left: 0;
}

.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigationItem {
  display: block;
  color: rgba($bsi-white, 0.8);
  padding: 0 0.7143rem;
  text-decoration: none;
}

.list a:hover {
  color: rgba($bsi-white, 0.8);
  background-color: rgba($bsi-white, 0.07);
}

.linkText {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 4.2857rem;
  border-bottom: 3px solid $bsi-color-dark-base;
}

.list :global(.active) .linkText {
  color: $bsi-white;
  border-bottom: 3px solid $bsi-color-accent-yellow-base;
}

.root.isMobile {
  display: block;
  height: auto;
  position: absolute;
  width: 100%;
  background-color: $bsi-color-dark-base;
}

.isMobile .list {
  width: 100%;
  display: block;
}

.isMobile .list .navigationItem {
  display: flex;
  align-items: center;
  height: 3.4286rem;
  width: 100%;
  color: rgba($bsi-color-light-base, 0.8);
  padding-left: 0;
  background-color: $bsi-color-dark-base;
  border-left: 3px solid $bsi-color-dark-base;
}

.isMobile .list :global(.active) {
  color: $bsi-white;
  border-left: 3px solid $bsi-color-accent-yellow-base;
}

.isMobile .list .linkText {
  display: inline;
  height: auto;
  border: none;
  margin-left: 1.1429rem;
}

.disabledText {
  color: $bsi-color-dark-lighter;
}
