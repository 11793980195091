@import "../../../../index.scss";

.bodyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: stretch;
  padding: 0rem 6.2857rem 2.7857rem 3.9286rem;
}

.imageContainer {
  display: flex;
  position: relative;
  flex-basis: 32.3571rem;
}

.expiredImage {
  max-width: 32.3571rem;
  width: 100%;
  max-height: 18.2143rem;
  background: $bsi-white;
  overflow: hidden;
  border-radius: 0.2857rem;

  img {
    object-fit: cover;
    opacity: 0.1;
    border: 1px solid rgba($bsi-white, 0.25);
  }
}

.expiredContent {
  opacity: 0.45;

  .rightBorder {
    border-right: 1px solid $bsi-white;
  }
}

.expiredContainer {
  .mobileIconContainer {
    opacity: 0.5;
  }

  .flowTitle {
    opacity: 0.5;
    margin-top: 1.0625rem;
  }

  .expiredBackGround {
    width: auto;
    width: 100%;
    height: 8.42rem;
    overflow: hidden;
    border-radius: 0.2857rem;
    margin-bottom: 1rem;
  }

  .backgroundPlaceholder {
    width: auto;
    height: 8.4rem;
    background-color: $bsi-white;
    object-fit: cover;
    opacity: 0.9;
    border: 1px solid rgba($bsi-white, 0.25);
  }

  .expiredWarning {
    z-index: 1;
    padding: 1.625rem 2.25rem 1.3125rem 1.4375rem;
    width: calc(100% - 30px);
  }
}

.expiredButton .startButton,
.expiredButton .startButton:hover {
  background-color: $bsi-color-primary-blue-lighter;
  cursor: not-allowed;
}

.expiredWarning {
  position: absolute;
  color: $bsi-color-accent-red-base;
  padding: 5.9375rem 4.9375rem;
  width: 100%;

  .expiredWarningTitle {
    font-weight: 700;
    text-align: center;
  }
}

.expiredWarning {
  i {
    padding-right: 1.9375rem;

    &:before {
      font-size: 1.125rem;
    }
  }

  p {
    display: contents;
    font-size: 1rem;
  }

  div {
    padding-bottom: 0.5rem;
  }
}

.image {
  display: block;
  max-width: 32.3571rem;
  width: 100%;
  max-height: 18.2143rem;
  border: 1px solid rgba($bsi-white, 0.25);
  border-radius: 0.2857rem;
}

.dueIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  color: $bsi-white;
  background-color: $bsi-color-accent-red-base;
  font-size: 0.7143rem;
  border-radius: 0px 0.2857rem;
  padding: 0.0714rem 0.3571rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
}

.dueIconWrapper {
  display: flex;
  padding: 0.1429rem 0.3571rem 0.0714rem 0.3571rem;
  border: 1px solid $bsi-white;
  border-radius: 0.1429rem;
  align-items: center;
  justify-content: center;
  width: 0.8571rem;
  margin-right: 0.5714rem;
  /* Controls size/position of icon */
  font-size: 0.5rem;
  position: relative;
  top: -1px;
}

.content {
  padding-left: 2rem;
  flex: 1;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.rightBorder {
  border-right: 1px solid $bsi-color-light-dark;
  flex: 1;
  margin: 1.7857rem 0;
  padding-right: 2rem;
  width: 100%;
  /* Provides overflow */
  flex-basis: 20px;
}

.flowTitle {
  font-size: 1.4286rem;
  overflow-wrap: anywhere;
  font-family: $bsi-gotham-s-sm;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.description {
  overflow-wrap: anywhere;
  overflow-y: hidden;
  opacity: 0.8;
  font-family: $bsi-gotham-s-sm;
  line-height: 1.5;
  font-size: 1.1429rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-left: 2.3571rem;
}

.startButton {
  color: $bsi-white;
  font-weight: bold;
  background-color: $bsi-color-primary-blue-base;
  padding: 0.7143rem 5rem;
  white-space: nowrap;
  border-radius: 0.2857rem;
  border: none;
  cursor: pointer;
  font-size: 1.1429rem;
  letter-spacing: -1px;
}

.startButton:hover {
  background-color: $bsi-color-primary-blue-darker;
}

.flowIcon {
  background-color: $bsi-white;
  color: $bsi-black;
  font-weight: bold;
  font-size: 0.7143rem;
  border-radius: 0.2857rem;
  padding: 0 0.5714rem;
  line-height: 2;
}

.bottomContainer {
  width: inherit;
  align-items: center;
  color: $bsi-white;
  opacity: 0.6;
  font-size: 0.8571rem;
  margin-bottom: 0.4286rem;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.iconContainer {
  margin: 0 1.5rem;
  font-size: 0.5714rem;
}

.mobileIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 1525) {
  .exclamationIcon {
    position: relative;
    right: -1px;
  }
}

@media (max-width: 1079px) {
  .bodyContainer {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    padding-bottom: 1.4286rem;
    border-bottom: 1px solid $bsi-color-light-dark;
    margin: 0 1.2857rem 1.7143rem;
  }

  .expiredWarning {
    padding: 4.375rem 2.5rem;
  }

  .expiredContent .rightBorder {
    border-right: none;
  }

  .imageContainer {
    flex-basis: 45%;
  }

  .content {
    height: auto;
    padding-right: 1rem;
    padding-left: 1.5714rem;
  }

  .flowIcon {
    margin-left: 0.1429rem;
  }

  .rightBorder {
    border: none;
    padding: 0;
    margin-top: 1.2143rem;
  }

  .flowTitle {
    margin-bottom: 0.7143rem;
  }

  .buttonContainer {
    display: block;
    margin: 0 1.0714rem 1.6429rem;
    text-align: center;
  }

  .startButton {
    width: 99%;
  }

  .bottomContainer {
    margin-bottom: 0.7143rem;
  }
}

@media (max-width: 809px) {
  .content {
    padding: 0;
  }

  .flowIcon {
    padding: 0 0.5714rem;
    margin: 0;
  }

  .dueIconWrapper {
    top: -1px;
  }

  .dueIcon {
    position: relative;
    border-radius: $button-border-radius;
    padding-left: 0.4286rem;
  }

  .bodyContainer {
    padding-bottom: 0;
    margin: 0 1rem 0 1.1429rem;
  }

  .flowTitle {
    margin-bottom: 0.7143rem;
  }

  .rightBorder {
    overflow-y: visible;
    margin: 1.1429rem 0 1.7857rem;
  }

  .bottomContainer {
    margin-bottom: 1.4286rem;
  }

  .buttonContainer {
    margin-top: 1.6429rem;
    margin-bottom: 1.4286rem;
  }

  .startButton {
    padding: 0.7857rem 5rem;
  }
}
