.root {
  display: flex;
  justify-content: space-between;
  line-height: 2.5714rem;
  font-size: 1.1429rem;
}

.sortText {
  padding-right: 1.4286rem;
}

.hidden {
  display: none;
}
