@import "../../../../index.scss";

.root {
  position: sticky;
  top: 0;
  z-index: 100;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  background-color: $bsi-color-dark-base;
  color: $bsi-white;
  align-items: center;
  padding: 0 2rem;
}

.logoLink {
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 3.5rem;
}

.logo {
  width: 11.0714rem;
}

.previewLogo {
  color: $bsi-color-dark-lighter;
  font-size: 0.75rem;
  position: absolute;
  top: 2.7rem;
  left: 8.65rem;
  text-transform: uppercase;
}

.menuToggler {
  border: none;
  outline: none;
  background: none;
  color: $bsi-white;
  cursor: pointer;
  height: 4.2857rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0;
}

.search {
  width: 26rem;
}

.links {
  margin-left: auto;
  margin-right: 2rem;
}

.linksMobile {
  display: none;
}

.menuVisible {
  display: block;
}

.menuToggler :global(.icon.angle) {
  opacity: 0.65;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .headerWrapper {
    padding: 0 1.7143rem 0 2rem;
  }
  .smallLogo {
    margin-right: 2rem;
  }

  .logoLink {
    margin-right: 0;
  }

  .previewLogo {
    top: 2.625rem;
    left: 1.82rem;
    font-size: 0.5rem;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .headerWrapper {
    padding: 0 0.8571rem 0 1.1429rem;
  }

  .smallLogo {
    margin-right: 1.1429rem;
  }

  .search {
    margin-right: 0.7143rem;
  }

  .previewLogo {
    top: 2.625rem;
    left: 0.75rem;
    font-size: 0.5rem;
  }
}

/* sm breakpoint */
@media (max-width: 809px) {
  .headerWrapper {
    padding: 0 0.5714rem 0 1.1429rem;
  }

  .smallLogo {
    margin-right: 0.5rem;
  }

  .search {
    margin-left: 0.5rem;
    margin-right: 1rem;
  }

  .account {
    margin-left: auto;
  }

  .previewLogo {
    top: 3.025rem;
    left: 0.75rem;
    font-size: 0.5rem;
  }
}
