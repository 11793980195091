@import "../../../../index.scss";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4286rem;
  margin-left: -0.8571rem;
  padding-top: 5px;
}

.openMembersFlyout {
  display: flex;
  align-items: center;
}

.membersLabelOffset {
  display: flex;
  align-items: center;
  margin-bottom: -2px;
}

i:global(.icon).iconColor {
  color: $bsi-color-dark-light;
  font-size: 1.4286rem;
}

.membersLabel {
  font-size: 1.1429rem;
  padding-left: 0.5714rem;
}

.body {
  flex: 1;
  display: flex;
  overflow: hidden;
  border-top: 1px solid $bsi-color-light-dark;
}

.mainSection {
  flex-basis: 74%;
  display: flex;
  flex-direction: column;
  padding: 2.1429rem 1rem 0 0;
  border-right: 1px solid $bsi-color-light-dark;
  overflow: hidden auto;
}

.memberSection {
  flex: 1;
  padding: 1.7143rem 0.3571rem 1.7143rem 2.1429rem;

  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 10%;
  overflow: hidden;
}

.openMembersFlyout :global(.member-list-filter) {
  margin-right: 2rem;
}

.topPart {
  display: flex;
  flex-direction: row;
}

.imageContainer {
  flex-basis: 48%;
}

.imageContainer > * {
  height: 100%;
}

.groupInfo {
  flex: 1;
  padding-left: 1.5714rem;
  overflow: hidden;
}

.groupTitle {
  font-size: 1.2857rem;
  font-family: $bsi-gotham-s-sm;
  margin-bottom: 0.5714rem;

  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.restricted {
  margin: auto;
  height: 100%;
}

.groupDescription {
  font-size: 1rem;
  white-space: pre-line;
  color: $bsi-color-dark-base;
  margin-bottom: 1.7143rem;
  overflow-wrap: anywhere;
}

.controls {
  padding-top: 3.3571rem;
}

.sortContainer {
  /* remove when group content is implemented */
  display: none;
  text-align: right;
  padding-top: 1.8571rem;
}

.sortLabel {
  font-size: 1.1429rem;
  padding-right: 1.4286rem;
}

.groupContent {
  flex: 1;
  padding-top: 0;
}

.cardSpacer {
  padding-bottom: 1.7143rem;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .mainSection {
    flex-basis: 100%;
    border-right: none;
  }

  .imageContainer {
    flex-basis: 44%;
  }

  .groupInfo {
    padding-left: 2rem;
  }

  .controls {
    padding-top: 3.4286rem;
  }
}

/* sm breakpoint  */
@media (max-width: 809px) {
  .topPart {
    flex-direction: column;
  }

  .imageContainer {
    flex-basis: auto;
  }

  .groupInfo {
    padding-left: 0;
  }

  .mainSection {
    padding-left: 1rem;
  }
}
