@import "../../../../index.scss";

.memberCountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $bsi-color-dark-light;
  font-size: 1.4286rem;
  margin-bottom: 1.5714rem;
}

.memberCountHeaderWrapper {
  display: flex;
  align-items: center;
}

.memberCount {
  font-weight: bold;
  margin: 0;
  margin-left: 0.5714rem;
  color: $bsi-color-dark-base;
  font-size: 1.1429rem;
  line-height: 1;
}

.memberCount span {
  padding-left: 5px;
}

.inputWrapper {
  margin-bottom: 1.4286rem;
}

.noMembers {
  margin: 0 2rem;
}
