@import "../../../../../index.scss";

.root {
  padding-top: 0.7em;
  padding-bottom: 7.143rem;
  overflow: hidden;
}

.root i {
  margin: 0.7143rem;
  font-size: 1.4286rem;
  color: $bsi-color-dark-light;
  cursor: pointer;
}

.root i:hover {
  color: $bsi-color-dark-base;
}

.tabs {
  position: relative;
  display: flex;
  height: 3.1429rem;
  box-shadow: calc(-1 * $box-shadow-offset) $box-shadow-offset 4px calc(-1 * $box-shadow-offset) rgba($bsi-black, 0.16);
  /* we want to stretch the box shadow line to the very edge, keeping the internals aligned to the baseline
     hidden overflow on root will keep box shadow from showing up on left and right edges
  */
  margin-right: calc(-2 * $box-shadow-offset);
  padding-right: calc(2 * $box-shadow-offset);
}

.tab {
  margin: 1rem 2.4rem 0 1rem;
  cursor: pointer;
  opacity: 0.5;
  color: $bsi-color-dark-base;
}

.tab.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  font-weight: bold;
}

.tab.active::after {
  width: 33%;
  min-width: 2.5rem;
  content: "";
  border-bottom: 2px solid $bsi-color-primary-blue-base;
}

.root section {
  padding-top: 2rem;
}

.root .title {
  font-size: 1.4286rem;
  font-family: $bsi-gotham-s-sm;
  font-weight: 900;
  margin-bottom: 1.1429rem;
}

.root .description {
  font-size: 1.1429rem;
  line-height: 1.5;
  color: $bsi-color-dark-light;
}

.loaderContainer {
  position: relative;
}

@media (max-width: 809px) {
  .root {
    padding: 0;
  }
}

@media (max-width: 400px) {
  .tab {
    margin: 1rem 0 0 1rem;
  }
}
