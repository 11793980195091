.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .restricted {
    margin: auto;
  }
}
