@import "../../../../index.scss";

.root {
  display: flex;
  border: solid 0.143rem;
  border-radius: 0.286rem;

  &.error {
    border-color: $bsi-color-accent-red-base;
  }

  .stripe {
    width: 0.857rem;

    &.error {
      background-color: $bsi-color-accent-red-base;
    }
  }

  .content {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.821rem 1.286rem;

    .icon {
      font-size: 1.429rem;

      &.error {
        color: $bsi-color-accent-red-base;
      }
    }

    .action {
      width: auto;
      margin-left: auto;
      flex-shrink: 0;
      padding-left: 1.15rem;
      padding-right: 1.15rem;

      &_text {
        color: $bsi-black;
        font-size: 1.143rem;
      }
    }

    & > *:not(:last-child) {
      margin-right: 1.286rem;
    }
  }
}
