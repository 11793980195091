@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: $page-padding;
  margin: 0 auto;
  width: calc(100% - 2 * $page-wrapper-padding);
  max-width: $large-screen-page-wrapper-width;
  box-sizing: content-box;
  flex-grow: 1;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .root {
    padding: $page-wrapper-padding $page-wrapper-padding 0 $page-wrapper-padding;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .root {
    padding: 1.1429rem 1.1429rem 0 1.1429rem;
  }
}
