@import "../../../../index.scss";

.titleContainerOpened {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.titleContainerClosed {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

.sectionExpandButton {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.filterSectionName {
  font-weight: bold;
  color: $bsi-color-dark-base;
  font-size: 1rem;
}

.iconContainer {
  flex: 1;
  text-align: right;
  font-size: 1.2857rem;
  line-height: 0.9;
}

.mutedIcon {
  color: $bsi-color-dark-light;
  flex: 1;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1429rem;
  word-break: break-all;
}

.checkboxSpacing {
  margin-right: 1.0714rem;
}

.numItems {
  text-align: right;
  flex: 1;
  color: $bsi-color-dark-lighter;
  font-size: 1rem;
}

.moreButton {
  background: none;
  border: none;
  text-decoration: underline;
  color: $bsi-color-primary-blue-base;
  font-size: 1.1429rem;
  padding: 0;
  cursor: pointer;
}

/* Preventing highlighting on the filter labels when double clicking
  https://stackoverflow.com/questions/139157/what-is-the-best-way-to-prevent-highlighting-of-text-when-clicking-on-its-contai
*/
.noSelect {
  user-select: none;
}

.hidden {
  display: none;
}

.checkboxChecked {
  font-weight: bold;
}

.subfilter {
  padding-left: 1.85714rem;
  margin-top: 1.5rem;

  .titleContainerOpened {
    margin-bottom: 1rem;
  }
}

.disabled {
  .titleContainerOpened {
    opacity: 50%;
    cursor: default;
  }
}
