@import "../../../../index.scss";

.button {
  background-color: $bsi-color-primary-blue-base;
  border: none;
  color: $bsi-white;
  font-weight: bold;
  width: 9.4286rem;
  padding: 0.7143rem 0;
  border-radius: $button-border-radius;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: $bsi-color-primary-blue-darker;
  }

  &.red {
    background-color: $bsi-color-accent-red-base;

    &:hover {
      background-color: $bsi-color-accent-red-dark;
    }

    &.basic {
      background-color: $bsi-white;
      color: $bsi-color-accent-red-base;
      box-shadow: 0 0 0 2px $bsi-color-accent-red-base;

      &:hover {
        color: $bsi-color-accent-red-dark;
        box-shadow: 0 0 0 2px $bsi-color-accent-red-dark;
      }
    }
  }

  // Copied from semantic ui css
  &.loading {
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0s linear, opacity 0.1s ease;
    transition: all 0s linear, opacity 0.1s ease;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -0.7857rem 0 0 -0.7857rem;
      width: 1.2857rem;
      height: 1.2857rem;
      border-radius: 500rem;
      border: 0.2em solid rgba($bsi-black, 0.15);
    }

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -0.7857rem 0 0 -0.7857rem;
      width: 1.2857rem;
      height: 1.2857rem;
      -webkit-animation: button-spin 0.6s linear;
      animation: button-spin 0.6s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: $bsi-white transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      -webkit-box-shadow: 0 0 0 1px transparent;
      box-shadow: 0 0 0 1px transparent;
    }
  }
}

.button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
  color: $bsi-white;
}

.fullWidth {
  width: 100%;
}

.secondaryButton {
  border: 2px solid;
  box-sizing: border-box;
  background-color: $bsi-white;
  color: $bsi-color-dark-light;
  width: 80%;
  margin-right: 0;

  &:hover {
    background-color: $bsi-white;
    border-color: $bsi-black;
  }
}

@keyframes button-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
