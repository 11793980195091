@import "../../../../index.scss";

.root {
  background: $bsi-color-accent-green-base;
  box-sizing: border-box;
  color: $bsi-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.7143rem;
  transition: margin-top $medium;

  &.vertical {
    flex-direction: column;
  }

  .body {
    flex: 1;
    margin: 0;

    .link {
      color: $bsi-white;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  :global(.ui.button) {
    &.actionButton {
      width: 200px;
      font-weight: bold;
      margin-left: 1.7143rem;
    }

    &.fullWidth {
      margin-left: 0;
      margin-top: 1.4286rem;
      width: 100%;
    }
  }
}
