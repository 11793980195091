@import "../../../../index.scss";

.companyLogo {
  max-width: 5.4286rem;
}

.logoSpacing {
  margin-right: 1.0714rem;
}

.companyInfo {
  text-align: left;
  min-width: 11.2143rem;
}

.iconSpacer {
  margin-left: 1rem;
}

.icon {
  color: $bsi-color-dark-light;
}

.companyName {
  font-size: 1.2857rem;
  color: $bsi-color-dark-light;
  font-weight: 700;
  margin-bottom: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stackedIcon {
  font-size: 2.4286rem;
}

.stackedIcon.logoSpacing {
  margin-right: 0.5rem;
}

.stackedIcon.smaller {
  font-size: 1.7143rem;
}
