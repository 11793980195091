.vjs-menu {
  bottom: 1.8em !important;
}

.vjs-menu-content {
  right: 1em;
  max-height: min-content !important;
}

.vjs-menu > .vjs-menu-content {
  display: flex !important;
  flex-direction: column;
}

.vjs-settings-sub-menu > .vjs-menu > .vjs-menu-content {
  overflow-y: auto !important;
}

@media (max-width: 630px) {
  .vjs-menu-content {
    max-height: 231px !important;
  }
}

@media (max-width: 570px) {
  .vjs-menu-content {
    max-height: 198px !important;
  }
}

@media (max-width: 510px) {
  .vjs-menu-content {
    max-height: 165px !important;
  }
}

@media (max-width: 450px) {
  .vjs-menu-content {
    max-height: 132px !important;
  }
}

@media (max-width: 390px) {
  .vjs-menu-content {
    max-height: 99px !important;
  }
}

@media (max-width: 330px) {
  .vjs-menu-content {
    max-height: 66px !important;
  }
}

.vjs-settings-sub-menu {
  position: relative;
  top: 5.5em;
}

.video-js.vjs-settings-menu {
  display: block;
}

.video-js .vjs-settings-menu .vjs-menu-content {
  overflow: inherit;
}

.video-js .vjs-settings-menu .vjs-menu-item {
  align-self: stretch;
  display: inline-block;
  text-align: left;
  padding: 0.7em 0;
  -webkit-user-select: none;
  user-select: none;
}

.video-js .vjs-settings-menu .vjs-settings-sub-menu-value,
.video-js .vjs-settings-menu .vjs-settings-sub-menu-title {
  text-align: left;
  display: inline-block;
  padding: 0 7px;
}

.vjs-settings-sub-menu-value,
.vjs-menu-item-text {
  text-transform: capitalize;
}

.video-js .vjs-settings-menu.vjs-menu-button-popup > .vjs-menu {
  width: 17em;
  left: -10em;
}

.video-js .vjs-settings-menu.vjs-menu-button-popup .vjs-settings-sub-menu .vjs-menu {
  width: auto;
  display: block;

  .vjs-menu-content {
    width: auto;
  }

  .vjs-menu-item {
    text-align: left;
    padding: 0.7em 1em;
  }
}

.video-js .vjs-settings-menu .vjs-settings-sub-menu .vjs-menu-content li {
  font-size: 1em;
}
.video-js .vjs-settings-menu .vjs-settings-sub-menu .vjs-menu-item {
  text-align: center;
}

.video-js .vjs-settings-sub-menu-title {
  width: 8em;
  text-transform: initial;
}

.vjs-menu-item-text {
  font-weight: bold !important;
}

.vjs-menu .vjs-menu {
  left: calc(1em - 2px) !important;
}
