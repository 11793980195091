@import "../../../../index.scss";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
}

.root .eventContent {
  display: inline-flex;
  width: 80%;
  align-self: center;
  overflow-y: auto;
  padding-top: 1.4286rem;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 4.7143rem;
  padding-left: 1.4286rem;
  padding-bottom: 0.7143rem;
  border-bottom: solid 1px $bsi-color-light-dark;
}

.header .title {
  display: inline-flex;
  justify-content: flex-start;
  margin: auto 0;
  font-family: $bsi-gotham-s-sm;
  font-size: 1.4286rem;
  font-weight: 900;
  color: $bsi-color-dark-base;
  overflow-wrap: anywhere;
}

.info {
  color: $bsi-color-dark-base;
  font-size: 1.1429rem;
  font-weight: 500;
  line-height: 1.88;
  margin: auto 0;
  flex-shrink: 0;
}

@media (max-width: 809px) {
  .header {
    display: none;
  }

  .root .eventContent {
    width: 100%;
    padding-top: 0;
  }
}
