/* ***** SASS VARIABLES ***** */

/* FONTS */

$bsi-gotham-s-sm: "GothamSSm", Helvetica, Arial, sans-serif;
$bsi-gt-walsheim: "GTWalsheim", Helvetica, Arial, sans-serif;
$bsi-base-font-size: 14px;

/* COLORS */

$bsi-black: #000;
$bsi-white: #fff;

$bsi-color-primary-blue-lighter: #90c2f3;
$bsi-color-primary-blue-base: #288bed;
$bsi-color-primary-blue-darker: #2a67aa;

$bsi-color-accent-yellow-base: #ffbf48;
$bsi-color-accent-red-base: #d01e1e;
$bsi-color-accent-red-dark: #9a1f1f;
$bsi-color-accent-green-base: #1ed061;

$bsi-color-dark-base: #232328;
$bsi-color-dark-light: #686569;
$bsi-color-dark-lighter: #9c989e;
$bsi-color-light-darker: #c9c9c9;
$bsi-color-light-dark: #e5e5e5;
$bsi-color-light-base: $bsi-white;

/* CUSTOM VARIABLES */

$assessment-footer-height: 5.2143rem;
$footer-height: 5.1429rem;
$box-shadow-offset: 5px;
$flow-headers-height: 14.9286rem;
$progress-line-width: 2px;
$page-headers-height: 10.2143rem;
$scroll-width: 0.7143rem;
$card-border-radius: 5px;
$border-radius: $card-border-radius $card-border-radius 0 0;
$circle-icon-width: 0.8571rem;
$half-icon-width: calc($circle-icon-width / 2);
$max-content-width: 1660px;
$button-border-radius: 4px;
$app-header-height: 60px;
$large-screen-page-wrapper-width: 1328px;
$page-wrapper-padding: 2rem;
$top-page-padding: 1.7857rem;
$left-right-page-padding: 3.7143rem;
$bottom-page-padding: 0;
$page-padding: $top-page-padding $left-right-page-padding $bottom-page-padding $left-right-page-padding;
$img-aspect-ratio-padding: 56.25%;
/* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625), for when aspect-ratio css not supported */
$banner-height: 120px;
$z-index-overlay: 9999;
$big-play-button-size: 5rem;
$player-border-radius: 0.25rem;
$default-gutter: 0.375rem;
$playhead-thickness: 1.2rem;
$play-control-width: 3.2rem;

/* TIME */

$fast: 0.2s;
$medium: 0.5s;
$slow: 1s;

/* ****************************** */

body {
  margin: 0;
  font-family: $bsi-gotham-s-sm;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $bsi-base-font-size;
  color: $bsi-color-dark-base;
  overflow: hidden;
}

/* headings */

body h1 {
  margin-top: 0;
  font-family: $bsi-gt-walsheim;
  font-weight: 900;
  font-size: 1.8571rem;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100dvh;
}

#main {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-Light.woff") format("woff"),
    url("./assets/fonts/GothamSSm-Light.otf") format("opentype");
  text-rendering: optimizeLegibility;
}

/* Light Italic */

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Book (regular/normal) */

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-Book.otf") format("opentype");
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* Book Italic */

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-BookItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Medium */

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-Medium.woff") format("woff"),
    url("./assets/fonts/GothamSSm-Medium.otf") format("opentype");
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Medium Italic*/

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* Bold */

@font-face {
  font-family: "GothamSSm";
  font-weight: 700;
  src: url("./assets/fonts/GothamSSm-Bold.otf") format("opentype");
  text-rendering: optimizeLegibility;
}

/* Bold Italic*/

@font-face {
  font-family: "GothamSSm";
  src: url("./assets/fonts/GothamSSm-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

/* GT Walsheim Regular (400) */

@font-face {
  font-family: "GTWalsheim";
  src: url("./assets/fonts/GT-Walsheim-Regular.otf") format("opentype");
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

/* GT Walsheim Bold (700) */

@font-face {
  font-family: "GTWalsheim";
  src: url("./assets/fonts/GT-Walsheim-Bold.otf") format("opentype");
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* GT Walsheim Very Bold (900) */

@font-face {
  font-family: "GTWalsheim";
  src: url("./assets/fonts/GT-Walsheim-Black.otf") format("opentype");
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Checkbox";
  src: url("./assets//fonts/icons.woff") format("woff");
  font-weight: 900;
}

.stub {
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    opacity: 0.2;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: repeating-linear-gradient(
      45deg,
      rgba($bsi-black, 0.1),
      rgba($bsi-black, 0.1) 10px,
      rgba($bsi-black, 0.2) 10px,
      rgba($bsi-black, 0.2) 20px
    );
  }

  &:hover:after {
    background: rgba($bsi-black, 0.1);
    border-radius: 5px;
    bottom: 10px;
    color: rgba($bsi-color-light-base, 0.9);
    content: "stubbed functionality";
    left: 10px;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 190px;
  }
}

// SEMANTIC OVERRIDES //

.ui.checkbox label::before,
.ui.radio label::before {
  border-color: $bsi-color-light-darker !important;
}
