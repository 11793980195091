@import "../../../../index.scss";

.root {
  /* we want to keep the constant aspect ratio, dimensions are taken from zeplin */
  height: 0;
  overflow: hidden;
  padding-top: calc(258 / 459 * 100%);
  background: $bsi-white;
  position: relative;
}

.stretchContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.groupImage {
  border-radius: $card-border-radius;
  width: 100%;
  object-fit: cover;
}

.noImage {
  background-color: $bsi-color-light-darker;
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
}

.noImage .usersIcon {
  font-size: 5rem;
  margin: auto;
  color: $bsi-white;
}
