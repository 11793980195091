@import "../../../../index.scss";

.root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.root .noResultsIcon {
  margin-bottom: 3.125rem;
  font-size: 10em;
  color: $bsi-color-light-dark;
}

.title {
  text-align: center;
  font-size: 1.8571rem;
  font-weight: bold;
  color: $bsi-color-dark-base;
  margin-bottom: 2rem;
  font-family: $bsi-gt-walsheim;
}

.description {
  font-size: 1.125em;
  line-height: 1.5;
  text-align: center;
  color: $bsi-color-dark-light;
  margin-bottom: 2.2rem;
}

.markup {
  max-width: 80%;
  opacity: 0.75;
}

.fullWidth .description {
  max-width: 100%;
}
