@import "../../../../index.scss";

.root {
  & > *:not(:last-child) {
    margin-bottom: 1.786rem;
  }

  .viewAll {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    font-size: 1.1429rem;
    color: $bsi-color-primary-blue-base;
  }
}
