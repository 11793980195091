@import "../../../../index.scss";

.root {
  padding: 2rem 2rem 0 2rem;
  height: calc(100vh - $app-header-height);
  width: 100%;
  max-width: $max-content-width;
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

@media (max-width: 1079px) {
  .root {
    padding: 1.1429rem 1.1429rem 0 1.1429rem;
  }
}
