@import "../../../../index.scss";

.memberRoot {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.memberImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.memberInfo {
  flex: 1;
  align-self: stretch;
  margin-left: 2.2857rem;
  line-height: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.memberName {
  display: block;
  font-weight: bold;
  color: $bsi-black;
  font-size: 1rem;
  margin: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.memberTitle {
  display: inline-block;
  font-size: 1rem;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.private {
  background-color: $bsi-color-dark-base;
  border-radius: 0.7143rem;
  width: 100%;
  height: 12px;
}

.memberName.private {
  opacity: 0.5;
}

.memberTitle.private {
  opacity: 0.3;
  height: 8px;
}
