@import "../../../../../../index.scss";

.root {
  border: 1px solid $bsi-color-light-darker;
  border-radius: $card-border-radius;
  color: $bsi-color-dark-base;
  text-decoration: none;
  position: relative;
  display: flex;
  width: 100%;

  .body {
    width: 100%;

    &.expired {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &.vertical {
    flex-direction: column;

    .body {
      padding: 1.2rem 1rem 0.715rem;
    }
  }

  &.horizontal {
    height: 13.7857rem;

    .body {
      padding: 1rem 0.5rem 0.4rem 1.9286rem;
    }
  }
}

.imageContainer {
  position: relative;
  width: auto;
  height: 100%;
  border-radius: $button-border-radius 0 0 $button-border-radius;
  aspect-ratio: 16 / 9;

  &.expired {
    .image {
      opacity: 0.2;
      cursor: not-allowed;

      &:hover {
        opacity: 0.05;
      }
    }
  }

  .expiredWarning {
    position: absolute;
    color: $bsi-color-accent-red-base;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;

    .expiredWarningTitle {
      display: flex;
      font-weight: 700;
      padding-bottom: 0.5rem;

      i {
        padding-right: 1.9375rem;

        &:before {
          font-size: 1.125rem;
        }
      }
    }
  }

  @supports not (aspect-ratio: 16 /9) {
    padding-top: $img-aspect-ratio-padding;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;

  .contentIcon {
    display: flex;
    gap: 0.5rem;
  }
}

.priorityContainer {
  padding-left: 1.4286rem;
}

.metaContainer {
  margin-top: auto;
}

.length {
  color: $bsi-white;
  font-weight: bold;
  padding: 0.125rem 0.5rem;
  background-color: $bsi-color-dark-base;
  opacity: 0.7;
  border-radius: 0.3571rem 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
}
