.root {
  display: flex;
  align-items: center;
}

@media (max-width: 809px) {
  .title {
    font-size: 1.4286rem;
  }
}
