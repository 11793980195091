@import "../../../../../index.scss";

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.question {
  padding: 1.7857rem 0 0 1.4286rem;
  overflow-y: auto;
  flex: 1;
}

.footer {
  padding: 1.2857rem 2.1429rem;
  box-shadow: 3px 0 6px 0 rgba($bsi-black, 0.16);
  border-top: solid 1px $bsi-color-light-darker;
  width: 120%;
  background-color: $bsi-white;
}

/* sm breakpoint */
@media (max-width: 809px) {
  .footer {
    margin-left: -16px;
  }

  .question {
    padding: 1.4286rem;
  }
}
