@import "../../../../index.scss";

.root {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Copying from AppPageWrapper so I can apply to everything but top section */
.homePageWrapper {
  padding: 0rem 3.7143rem;
  margin: auto;
  max-width: $large-screen-page-wrapper-width;
  box-sizing: content-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-top: 1.2143rem;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.teamsHeader {
  padding-bottom: 1rem;
  margin-bottom: 1.6429rem;
  border-bottom: 1px solid $bsi-color-light-dark;
}

.title {
  margin: 0;
}

.company {
  display: flex;
  align-items: center;
  max-width: 28.5714rem;
}

.company img {
  max-width: 3.2857rem;
}

.company .companyName {
  font-size: 16px;
  color: $bsi-color-dark-base;
}

.highlightedLearningWrapper {
  margin-bottom: 3.7857rem;
}

.progressBarsWrapper {
  margin-bottom: 2.3571rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content {
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex: 1;
}

.progressBarsSection:not(:first-child) {
  margin-left: 1.375rem;
}

/* Empty State */

.emptyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12.8571rem auto 0;
  max-width: 28.5714rem;
}

.iconWrapper {
  font-size: 50px;
  margin-bottom: 65px;
}

.iconColor {
  color: $bsi-color-light-darker;
}

.emptyDescription {
  color: $bsi-color-dark-light;
  margin-bottom: 2.7143rem;
  font-size: 1.2857rem;
  text-align: center;
}

.fullWidth {
  width: 100%;
}

/* End empty state */

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .homePageWrapper {
    padding: 0rem 3.875rem 0 3rem;
  }

  .header {
    padding-top: 1rem;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .homePageWrapper {
    padding: 0rem 1.1429rem;
  }

  .header {
    padding-top: 1.1429rem;
  }

  .progressBarsWrapper {
    flex-direction: column;
    margin-bottom: 0.6429rem;
    & > * {
      width: 31rem;
    }
  }

  .progressBarsSection:not(:first-child) {
    margin-left: auto;
  }

  .highlightedLearningWrapper {
    margin-bottom: 1.8571rem;
  }
}

@media (max-width: 809px) {
  .stickyHeader {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: $bsi-white;
    z-index: 50;

    border-bottom: 2px solid $bsi-white;
  }

  .homePageWrapper {
    padding: 0 1rem;
  }

  .shadow {
    border-bottom: 2px solid $bsi-color-light-dark;
  }

  .header {
    padding-top: 0.4286rem;
    padding-bottom: 0.1429rem;
  }

  .title {
    font-size: 1.4286rem;
    padding-left: 0.1429rem;
  }

  .content {
    margin-top: 0;
  }

  .highlightedLearningWrapper {
    margin-bottom: 1.9286rem;
  }

  .progressBarsWrapper {
    margin-bottom: 0;
  }

  .progressBarsSection:not(:last-child) {
    padding-bottom: 0.5rem;
  }

  .emptyWrapper {
    margin-top: 3.5714rem;
  }
}
