@import "../../../../index.scss";

.flyout {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: fixed;
  background-color: $bsi-white;
  box-shadow: 0 0 1.9286rem 0 rgba($bsi-black, 0.4);
  border-radius: $button-border-radius;
  z-index: 100;
  transition: top $medium ease-in-out, right $medium ease-in-out, bottom $medium ease-in-out, left $medium ease-in-out;
}

.flyout.opened {
  visibility: visible;
}

.flyout.opened.top {
  top: 0;
}

.flyout.opened.bottom {
  bottom: 0;
}

.flyout.opened.left {
  left: 0;
}

.flyout.opened.right {
  right: 0;
}

.flyout.hidden {
  visibility: hidden;
}
