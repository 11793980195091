@import "../../../../../index.scss";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.root :global(i.icon) {
  font-size: 10rem;
  color: $bsi-color-light-dark;
}

.delayPlaceholderDesc {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  color: $bsi-color-dark-light;
  white-space: pre-line;
  width: 36.25rem;
  text-align: center;
  line-height: 1.5;
}
