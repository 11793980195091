@import "../../../../index.scss";

.root {
  border-bottom: solid 1px $bsi-color-light-dark;
  box-sizing: border-box;
  padding-bottom: 0.3rem;
  position: relative;
}

.root:not(:last-child) {
  margin-bottom: 19px;
}

.content {
  height: 128px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  height: 1.249rem;
  align-items: center;
}

.type {
  font-size: 0.7857rem;
  font-weight: 500;
  line-height: 1.44;
  color: $bsi-color-dark-light;
}

.dueDate {
  font-size: 0.6429rem;
  font-weight: bold;
  color: $bsi-color-accent-red-base;
  padding: 0.1429rem 0.5rem;
  margin-right: 0.2143rem;
  border-radius: 3px;
  border: solid 1px $bsi-color-accent-red-base;
  line-height: normal;
}

.title {
  display: inline;
  margin: 0.5rem 0 0 1px;
  overflow: hidden;
  overflow-wrap: anywhere;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: -0.26px;
  color: $bsi-color-dark-base;
}

.meta {
  font-size: 0.8571rem;
  line-height: 1.17;
  overflow-wrap: anywhere;
  height: 14px;
  color: $bsi-color-dark-lighter;
  margin: 23px 0 8px 2px;
}

@media (max-width: 1080px) {
  .meta {
    display: flex;
    justify-content: flex-start;
  }
}

.progress {
  display: flex;
  align-items: flex-end;
}

.outer,
.inner {
  height: 0.5714rem;
  border-radius: 4px;
}

.outer {
  background-color: $bsi-color-light-dark;
  width: 100%;
  margin-left: 0.4286rem;
}

.inner {
  background-color: $bsi-color-accent-yellow-base;
}

.progress .text {
  color: $bsi-color-dark-light;
}

.expiredWarning {
  position: absolute;
  color: $bsi-color-accent-red-base;
  padding: 1.43rem 0;
  width: 100%;

  .expiredWarningTitle {
    font-weight: 700;
  }

  i {
    padding-right: 1.9375rem;

    &:before {
      font-size: 1.125rem;
    }
  }

  p {
    display: contents;
    font-size: 1rem;
  }

  div {
    padding-bottom: 0.5rem;
  }
}

.expiredBody {
  opacity: 0.2;
  cursor: not-allowed;

  &:hover {
    opacity: 0.05;
  }
}
