@import "../../../../../index.scss";

.root {
  position: relative;
}

.toggler {
  border: none;
  background: none;
  color: $bsi-white;
  cursor: pointer;
  height: 4.2857rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0;
}

.toggler :global(.icon.angle) {
  opacity: 0.65;
}

.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatarImg {
  height: 2.8571rem;
  width: 2.8571rem;
  border: 1px solid rgba($bsi-color-light-base, 0.8);
  border-radius: 50%;
  object-fit: cover;
}

.label {
  margin-right: 0.7143rem;
  margin-left: 1.1429rem;
  color: rgba($bsi-white, 0.8);
}

.inner {
  display: none;
  position: absolute;
  width: 21.8571rem;
  right: -1.375rem;
  top: 4.6429rem;
  box-shadow: 1px 3px 8px 0px rgba($bsi-color-dark-base, 0.2);
  border: 1px solid $bsi-color-light-dark;
  border-radius: 4px;
  background-color: $bsi-white;
}

.inner.opened {
  display: block;
}

.inner:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 0 18px 15px 18px;
  border-color: transparent transparent $bsi-white transparent;
  top: -10px;
  right: 10px;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .label {
    display: none;
  }
  .avatar {
    margin-right: 0.3571rem;
  }
  .inner {
    right: -1.2857rem;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .inner {
    right: -0.5rem;
  }
}

/* sm breakpoint */
@media (max-width: 809px) {
  .avatar {
    margin-right: 0.5rem;
  }
  .inner {
    right: -0.2143rem;
  }
}
