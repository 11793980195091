@import "../../../../index.scss";

.root {
  border-right: 1px solid $bsi-color-light-dark;
  padding-right: 1.375rem;
  flex: 1;

  margin-top: 0.7857rem;
}

.noBorder {
  border: none;
}

.viewAll {
  padding: 0.2rem 0;
  text-decoration: underline;
  text-align: end;
  font-size: 1.1429rem;
}

.viewAllRedirectText {
  color: $bsi-color-primary-blue-base;
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .root {
    border-right: none;
    padding-right: 0;
  }
}

@media (max-width: 1079px) and (min-width: 810px) {
  .viewAll {
    padding: 0.3571rem 0.1429rem 1.7857rem 0;
  }
}

/* sm breakpoint */
@media (max-width: 809px) {
  .viewAll {
    padding: 0.5rem 0rem 3.3571rem;
  }
}
