@import "../../../../../index.scss";

.type {
  background-color: $bsi-color-dark-base;
  border-radius: 0.2357rem;
  color: $bsi-white;
  padding: 0.0714rem 0.4286rem;
  font-size: 0.7143rem;
}

.iconText {
  padding-left: 0.1429rem;
  font-weight: bold;
}

.inverted {
  color: $bsi-black;
  background-color: $bsi-white;
}
