.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.description {
  max-width: 100%;
  margin-bottom: 4.5rem;
}

.iconContainer {
  margin-bottom: 4.2857rem;
}

.button {
  width: 196px;
}
