@import "../../../../index.scss";

.root {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr min-content;
}

.headerSpacing {
  margin-bottom: 1.4286rem;
}

.content {
  display: flex;
  min-height: 0;
}

.markComplete {
  & > div,
  & button {
    margin: 1rem 0 1rem 1.125rem;
    border: none;
    border-radius: 0.25rem;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: -0.0625rem;
  }
  & > div {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, min-content);
    align-items: center;
    color: $bsi-color-accent-green-base;
    padding: 0.5491rem 1.8125rem;
    color: $bsi-color-accent-green-base;
  }

  & button {
    padding: 0.6875rem 5rem;
    background-color: $bsi-color-primary-blue-base;
    color: $bsi-white;
    cursor: pointer;
  }
}

.relatedContent {
  flex-basis: 24%;
  padding-left: 1.65rem;
  padding-top: 1.5rem;
  border-top: 0.0625rem solid $bsi-color-light-dark;
}

.restricted {
  margin: auto;
}

@media (max-width: 1079px) {
  .content {
    display: block;
    height: inherit;
    overflow: auto;
  }

  .relatedContent {
    padding-left: 0;
    border-top: 0.0625rem solid $bsi-color-dark-lighter;
  }
}
