@import "../../../../index.scss";

.root {
  display: flex;
  align-items: center;
}

.menu {
  color: $bsi-color-dark-light;
}

.menu > :not(:last-child) {
  border-bottom: 2px solid $bsi-color-light-dark;
}

.item {
  cursor: pointer;
}

.progress {
  padding: 1.3571rem 0 0;
}

.company {
  display: flex;
  align-items: center;
  padding: 0.6429rem 1.0714rem;
  padding-bottom: 0.3571rem;
  min-height: 7rem;
}

.menufirst {
  padding-top: 0.1429rem;
}

.menusecond {
  padding-top: 0.1429rem;
}
