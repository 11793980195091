.root {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.root:disabled {
  cursor: unset;
}
