.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1.7143rem;
  row-gap: 1.7143rem;

  width: 100%;
  margin-top: 0.8571rem;
  margin-bottom: 2rem;
}

@media (max-width: 931px) {
  .root {
    gap: 1.1429rem !important;
  }
}
