@import "../../../../../index.scss";

.innerProgressContainer {
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 4px;
  text-align: center;
}

.progressText {
  margin-top: 1rem;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0.5;
}

.progressBar {
  height: 8px;
  border-radius: 4px;
}

.progressBarWide {
  height: 0.66rem;
  border-radius: 4px;
  margin-bottom: 0.3rem;
}

.opaque {
  opacity: 1;
}

.empty {
  background-color: $bsi-color-light-dark;
}

.grey {
  background-color: $bsi-color-dark-light;
}

.yellow {
  background-color: $bsi-color-accent-yellow-base;
}

.green {
  background-color: $bsi-color-accent-green-base;
}
