@import "../../../../index.scss";

.pageTitle {
  display: flex;
  align-items: center;
  padding-bottom: 1.4286rem;
  position: relative;
}

.header {
  display: flex;
  line-height: 1.4286rem;
  max-width: 90%;
  overflow-wrap: anywhere;
}

.header > h1 {
  min-width: 0;
  overflow-wrap: anywhere;
}

.pageTitle .header i {
  font-size: 1.4286rem;
}

.restartCourse {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
}

.restartCourse:hover {
  background: $bsi-color-light-dark;
  cursor: pointer;
}

.restartCourse [aria-label="toggle restart"] {
  color: $bsi-color-dark-light;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restartModal :global .confirm-modal-body .confirm-modal-content {
  font-size: 1.125rem;
  line-height: 1.5;
}

.container {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin-right: calc(-1 * $scroll-width);
  min-height: 0;
  width: 100%;
}

/* First child is expected to be FlowContents */
.container > :nth-child(1) {
  flex-basis: 24%;
  min-width: 0;
}

/* Second child is expected to be FlowAssetView */
.container > :nth-child(2) {
  flex: 1;
}

.error {
  margin: auto;
}

.assetView {
  height: 100%;
  position: relative;
}

.assetError {
  margin: 1.5rem 0.9rem;
}

.flowPageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $bsi-white;
}

@media (max-width: 1439px) {
  .container > :nth-child(1) {
    flex-basis: 28%;
  }
}

@media (max-width: 1079px) {
  .container > :nth-child(1) {
    flex-basis: 30%;
  }

  .header {
    max-width: 85%;
  }
}

@media (min-width: 1079px) {
  .pageTitle {
    border-bottom: 1px solid $bsi-color-light-dark;
  }
}

@media (max-width: 809px) {
  .pageTitle {
    display: block;
  }

  .header h1 {
    font-size: 1.4286rem;
    margin: 0 5rem 0 0.7143rem;
    line-height: 1.4286rem;
  }

  .header {
    max-width: 100%;
    margin-top: 1.0714rem;
  }

  .header i {
    color: $bsi-color-dark-light;
  }

  .container {
    border: none;
    margin-right: 0;
  }

  .container > :nth-child(1) {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: $bsi-white;
  }
}
