@import "../../../../../index.scss";

.imageContainer {
  position: relative;
  font-size: 0.8571rem;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: fill;
  object-position: center;
}

/* Used as an override to some of the above properties */
.fixedRatio {
  width: 100%;
  aspect-ratio: 16 / 9;
  min-height: auto;

  @supports not (aspect-ratio: 16 / 9) {
    padding-top: $img-aspect-ratio-padding;
  }
}

.setHeight {
  padding-top: 0;
  flex-basis: 49%;
  min-height: auto;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
  background: $bsi-black;
  transition: opacity $fast;
  opacity: 0;
}

.overlay.focused {
  opacity: 0.5;
  cursor: pointer;
}

/* Don't block hover from the overlay */
.playIcon {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  /* Not happy about these important styles, but semantic... */
  opacity: 0 !important;
  transition: opacity $fast;
  margin: 0;
}

.active {
  opacity: 1 !important;
}
