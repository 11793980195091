@import "../../../../index.scss";

.root {
  box-sizing: border-box;
  padding: 1.2857rem 1.4286rem;
  position: absolute;
  left: 2rem;
  bottom: 1.8rem;
  background-color: $bsi-white;
  box-shadow: 0.4286rem 0.4286rem 0.8571rem 0 rgba($bsi-black, 0.1);
  border-radius: $card-border-radius;
  transition: opacity $medium ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.root.visible {
  visibility: visible;
  opacity: 1;
}

.centerAlignedBox {
  display: flex;
  align-items: center;
}

.message {
  padding-right: 2.2857rem;
  white-space: pre-line;
}

.iconWrapper {
  padding-right: 0.7143rem;
}

.title {
  font-size: 1.7143rem;
  padding-bottom: 1.1429rem;
  border-bottom: 1px solid $bsi-color-light-dark;
  margin-bottom: 1.1429rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.success {
  border: 1px solid $bsi-color-accent-green-base;
  border-left: 0.9286rem solid $bsi-color-accent-green-base;
}

.warning {
  border: 1px solid $bsi-color-accent-yellow-base;
  border-left: 0.9286rem solid $bsi-color-accent-yellow-base;
}

.error {
  border: 1px solid $bsi-color-accent-red-base;
  border-left: 0.9286rem solid $bsi-color-accent-red-base;
}

.actionWrapper {
  padding: 0 20px;
}

.closeWrapper {
  font-size: 1.2857rem;
}
