@import "../../../../index.scss";

.inputWrapper {
  position: relative;
  height: 2.8571rem;
}

.searchInput {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba($bsi-color-dark-light, 0.5);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  color: $bsi-white;
  padding: 0px 3rem;
}

.searchInput:focus {
  outline: none;
}

.focused {
  border: 2px solid $bsi-color-accent-yellow-base;
}

.iconWrapper {
  font-size: 1.2143rem;
  background: none;
  border: none;
  outline: none;
}

.leftIcon {
  position: absolute;
  /* Difference between the icon's font-size and 1rem */
  top: calc(1rem - (1.2143rem - 1rem));
  left: 0px;
  padding-left: 1rem;
  color: rgba($bsi-white, 0.65);
}

.rightIcon {
  position: absolute;
  top: calc(1rem - (1.2143rem - 1rem));
  right: 0px;
  padding-right: 1rem;
  color: rgba($bsi-white, 0.65);
}

.rightIcon:hover {
  cursor: pointer;
}

.active {
  color: $bsi-white;
}

.allResults {
  text-decoration: underline;
  color: $bsi-color-primary-blue-base;
}
