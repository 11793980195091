@import "../../../../index.scss";

.segmentControl {
  display: flex;
  flex: 0 0 auto;
  position: relative;

  overflow-x: auto;
  scrollbar-width: none;
}

.segmentControl:before {
  content: "";
  position: absolute;
  bottom: 1px;
  right: 6px;
  left: 6px;
  height: 9px;
  z-index: 2;
  background-color: $bsi-white;
}

.segmentControl:after {
  content: "";
  box-shadow: 0 0 6px rgba($bsi-black, 0.08);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 5px;
  border-bottom: 1px solid $bsi-color-light-dark;
  z-index: 1;
}

.segmentControl::-webkit-scrollbar {
  display: none;
}

@media (max-width: 375px) {
  .segmentControl {
    margin-right: 0;
  }
}
