@import "../../../../index.scss";

.root {
  text-align: center;
  margin: auto;
}

.text {
  font-size: 1.1429rem;
  color: $bsi-color-dark-light;
  width: 36.5714rem;
  margin: auto;
  padding: 2.4286rem 0;
}

.root i.icon {
  font-size: 3.5714rem;
  opacity: 0.4;
  color: $bsi-color-primary-blue-base;
  pointer-events: none;
}

@media (max-width: 809px) {
  .root {
    width: 100%;
  }

  .text {
    width: 100%;
  }
}
