.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.placeholder {
  width: 100%;
}
