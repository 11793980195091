@import "../../../../../index.scss";

.root {
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
}

.eventInfo {
  flex-direction: column;
  overflow: auto;
}

.eventInfo > .title {
  font-size: 1.42rem;
  font-family: $bsi-gotham-s-sm;
  font-weight: 900;
  display: inline;
  overflow-wrap: anywhere;
}

.image {
  min-width: 17rem;
  height: 10rem;
}

.default {
  background-color: $bsi-color-light-dark;
  border-radius: $button-border-radius;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root > .image > .thumbnailPlaceholder > .defaultThumbnail {
  font-size: 5em;
}

.description {
  font-size: 1.14em;
  line-height: 1.5;
  color: $bsi-color-dark-light;
  margin-top: 1.25rem;
  flex-grow: 1;
  overflow-wrap: anywhere;
}

@media (max-width: 809px) {
  .root {
    gap: 1.5625rem;
    flex-direction: column;
  }

  .description {
    margin-top: 1rem;
    font-size: 0.875rem;
  }

  .eventInfo {
    padding: 0 1.25rem 0 0.625rem;
  }

  .eventInfo > .title {
    font-size: 1.125rem;
  }
}
