@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 1.1429rem);
  border: 1px solid $bsi-color-light-darker;
  color: $bsi-color-dark-base;
  text-decoration: none;
  border-radius: $card-border-radius;
  height: 35rem;
}

.cardImageContainer {
  border-radius: $border-radius;
}

.cardContent {
  display: flex;
  flex-direction: column;
  padding: 2.1429rem;
  flex: 1;
  min-height: 0;
}

.noImage {
  background-color: $bsi-color-light-darker;
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.noImage .usersIcon {
  font-size: 70px;
  margin: auto;
  color: $bsi-white;
}

.joined {
  color: $bsi-white;
  position: absolute;
  opacity: 0.8;
  background-color: $bsi-color-dark-base;
  padding: 0.5714rem;
  border-radius: 4px 0 0 0;
  bottom: 0;
  right: 0;
}

.title {
  font-size: 1.4286rem;
  font-weight: bold;
  line-height: 1.2;
  color: $bsi-black;
  overflow: hidden;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.title:hover {
  cursor: pointer;
  color: $bsi-color-primary-blue-base;
}

.description {
  flex-grow: 1;
  font-size: 1.1429rem;
  line-height: 1.19;
  margin: 0.7857rem 0 0;
  display: flex;
}

.descriptionText {
  align-self: flex-start;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.membersSection {
  display: flex;
  line-height: 2rem;
}

.memberPhotos {
  display: flex;
  margin-right: 10px;
}

.noMargin {
  margin-right: 0;
}

.memberCount {
  font-size: 1rem;
  font-weight: 500;
  color: $bsi-color-dark-base;
  white-space: nowrap;
}

.accessSection {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  line-height: 2.9rem;
  align-items: center;
}

.accessType {
  color: $bsi-color-dark-light;
  display: flex;
}

.accessIcon {
  font-size: 1.1429rem;
  height: 1.1429rem;
  width: 1rem;
}

.accessIcon svg {
  fill: $bsi-color-dark-light;
}

.accessText {
  font-size: 1rem;
  line-height: 2.4286rem;
  margin-left: 0.8571rem;
}

.button {
  width: 12.1429rem;
}

@media (max-width: 931px) {
  .root {
    width: calc(50% - 0.5714rem);
  }
}

@media (max-width: 620px) {
  .root {
    flex: 100%;
    height: auto;
  }
}
