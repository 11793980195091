@import "../../../../index.scss";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: $scroll-width;
  flex: 1;
  height: 100%;
}

.hidden {
  display: none;
}
