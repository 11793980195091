@import "../../../../index.scss";

.root {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: $scroll-width;
  height: 100%;
}

.hidden {
  display: none;
}

.passed {
  color: $bsi-color-accent-green-base;
}

.failed {
  color: $bsi-color-accent-red-base;
}
