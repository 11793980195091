@import "../../../../index.scss";

.root {
  position: relative;
  display: inline-block;
  min-width: 0;
}

.fluid {
  display: block;
  width: 100%;
}

.currentOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.78571429em 1.5em 0.78571429em 1em;

  border: 1px solid $bsi-color-light-dark;
  border-radius: 5px;
}

.root.expanded > .currentOption {
  border: 1px solid $bsi-color-primary-blue-base;
}

.root.expanded:not(.upward) > .currentOption {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.root.expanded.upward > .currentOption {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: $bsi-white;
  border: 1px solid $bsi-color-primary-blue-base;
  border-top: none;
  border-radius: 0 0 5px 5px;
  max-height: 300px;
  overflow-y: auto;
  margin: -1px 0;
  opacity: 0;
}

.root.upward > .options {
  top: auto;
  bottom: 100%;
  border-radius: 5px 5px 0 0;
  border-top: 1px solid $bsi-color-primary-blue-base;
  border-bottom: none;
}

.visible {
  display: block;
}

.nonTransparent {
  opacity: 1;
}

.item {
  /* Copied from semantic */
  padding: 0.78571429rem 1.14285714rem;
  cursor: pointer;
}

.item:hover {
  color: $bsi-color-dark-light;
}

.activeItem {
  background: $bsi-color-light-dark;
  font-weight: bold;
}

.iconSpacing {
  margin: 0;
  margin-left: 20px;
}
