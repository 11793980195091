@import "../../../../index.scss";

.root {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: $scroll-width;
  width: 100%;
  height: 100%;
}

.root button:not(:first-child) {
  margin-left: 1.0714rem;
}

.hidden {
  display: none;
}

.passed {
  color: $bsi-color-accent-green-base;
}
