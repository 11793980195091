@import "../../../../index.scss";

.root {
  border-right: 1px solid $bsi-color-light-dark;
}

.hidden {
  display: none;
}

.collapsed {
  max-width: 3.3571rem;
}

.header {
  height: 4.7143rem;
  line-height: 4.7143rem;
  display: flex;
  justify-content: space-between;
  border-bottom: $bsi-color-light-dark 1px solid;
  align-items: center;
}

.collapsed .header {
  border-bottom: none;
}

.left {
  display: flex;
  align-items: center;
}

.header .left i {
  font-size: 1.4286rem;
  color: $bsi-color-dark-base;
}

.title {
  font-size: 1.1429rem;
  color: $bsi-color-dark-base;
  margin-left: 0.6rem;
}

.total {
  color: $bsi-color-dark-lighter;
  margin-right: 1.1429rem;
}

.assets {
  height: calc(100vh - $flow-headers-height);
  overflow-y: auto;
  margin-left: -6px;
  padding-left: 6px;
}

@media (max-width: 809px) {
  .root {
    border-right: none;
  }

  .header {
    line-height: 0;
    height: auto;
    padding: 0 0 1.4375rem 0;
    border-bottom: none;
  }

  .total {
    color: $bsi-color-dark-lighter;
    margin-right: 0;
  }

  .assets {
    /* needs additional 66 pixels on tablet/mobile viewport because header is smaller */
    height: calc(100vh - $flow-headers-height + 66px);
  }
}
