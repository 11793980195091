@import "../../../../index.scss";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 13.75rem;
}

.root :global(i.mail) {
  font-size: 12rem;
  color: $bsi-color-light-dark;
  margin-bottom: 1.429rem;
}

.octagonExclamationIcon {
  height: 1.429rem;
  width: 1.429rem;
  fill: $bsi-color-dark-light;
  margin-bottom: 1rem;
}

.flowEmail {
  font-size: 1.125rem;
  color: $bsi-color-dark-light;
  white-space: pre-line;
  width: 31.25rem;
  text-align: center;
  line-height: 1.5;
}
