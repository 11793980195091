@import "../../../../index.scss";

.flyoutRoot {
  box-sizing: border-box;
  width: 360px;
  padding: 2.2857rem 2rem;
  background-color: $bsi-white;
}

.flyoutTitle {
  font-size: 1.7143rem;
  padding-bottom: 1.1429rem;
  border-bottom: 1px solid $bsi-color-light-dark;
  margin-bottom: 1.1429rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toastBody {
  line-height: 1.5;
}

.root {
  display: flex;
  flex-direction: column;
  height: calc(100% - $footer-height);
}

.header {
  font-size: 1.8571rem;
  margin: 0;
}

.body {
  margin: 2.5714rem 0;
  min-height: 0;
  flex: 1;
}

.contentContainer {
  height: 100%;
  overflow-y: auto;
}

.content {
  margin-top: 3.9286rem;
  margin-left: 1.7857rem;
}

.continueButton:hover {
  cursor: pointer;
}

.continueWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.1429rem;
}

.continueButton {
  box-sizing: border-box;
  padding: 0.6429rem 2.1429rem;
  font-weight: bold;
  background-color: $bsi-color-primary-blue-base;
  color: $bsi-white;
  border: none;
  border-radius: $button-border-radius;
}

.segment {
  margin-right: 5.5em;
  white-space: nowrap;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .content {
    margin-left: 0;
  }
}

@media (max-width: 1439px) and (min-width: 1080px) {
  .body {
    margin-left: 1.1429rem;
    margin-right: 1.1429rem;
  }

  .content {
    margin-top: 4.6429rem;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .content {
    margin-top: 2.5rem;
  }
}

@media (max-width: 375px) {
  .header {
    font-size: 1.4286rem;
  }

  .body {
    margin: 1.4286rem 0;
  }

  .content {
    margin-top: 2.8571rem;
    margin-bottom: 1.4286rem;
  }
}
