@import "../../../../index.scss";

.type {
  font-size: 0.7857rem;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  font-weight: 500;
  line-height: 1.44;
  color: $bsi-color-dark-light;
}

.iconText {
  padding-left: 0.1429rem;
  line-height: initial;
}

.courseIcon {
  margin: 0 -0.125rem 0 -0.125rem;
}

.background {
  color: $bsi-black;
  background-color: $bsi-white;
  border-radius: 0.2357rem;
  padding: 0.0714rem 0.4286rem;
  font-size: 0.7143rem;
  height: 1.569rem;

  & > svg path:last-child {
    fill: $bsi-color-dark-base;
  }
}
