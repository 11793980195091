@import "../../../../index.scss";

.filterButtonContainer {
  flex: 1;
  position: relative;
  top: 1px;
}

.filterButton {
  float: right;
  border: none;
  background: none;
  font-size: 1.1429rem;
  line-height: 0;
  padding: 0 0.4286rem;
  color: $bsi-color-dark-light;
  position: relative;
}

.filterCountContainer {
  position: absolute;
  top: 0;
  right: -1.1429rem;
  min-width: 2.4286rem;
}

.filterCount {
  background-color: $bsi-color-accent-yellow-base;
  border-radius: 0.7143rem;
  padding: 0.2143rem 0.9286rem;

  font-size: 0.7857rem;
  font-weight: bold;
}

.filterLabel {
  margin-left: 0.2857rem;
  color: $bsi-color-dark-base;
}

@media (max-width: 809px) {
  .filterLabel {
    display: none;
  }
}
