@import "../../../../../index.scss";

.root {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1429rem;
  padding: 1rem 1.0714rem 1rem 0.8571rem;
  width: 100%;
  border: none;
  background: none;
  color: $bsi-color-dark-light;
}

.active {
  color: $bsi-color-dark-base;
}

.root:hover {
  color: $bsi-color-dark-base;
  transition: 0.5s;
  background-color: $bsi-color-light-dark;
}

.text {
  margin-left: 0.8571rem;
}

.iconWrapper {
  font-size: 1.4286rem;
}
