@import "../../../../../index.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.6429rem;
  position: relative;
}

.headerText {
  margin: 0;
}

.controls {
  margin-left: auto;
  display: flex;
}

@media (max-width: 809px) {
  .header {
    padding-bottom: 1rem;
  }

  .headerText {
    font-size: 24px;
  }
}
