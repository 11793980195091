@import "../../../../../../index.scss";

.answer {
  position: relative;
  font-size: 1.1429rem;
  line-height: 1.5;
  color: $bsi-color-dark-light;
  white-space: pre-line;
  visibility: hidden;
  margin: 0;
  min-height: 50px;
}

.visible {
  visibility: visible;
  margin: 2rem 0;
  padding-top: 2rem;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
}
