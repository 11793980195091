.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.list li {
  margin-right: 0.7143rem;
}
