@import "../../../../../index.scss";

.root {
  display: flex;
}

.bulletBox {
  display: flex;
  width: 2.2857rem;
  height: 5.3571rem;
  align-items: center;
  position: relative;
}

.square {
  position: relative;
  left: -2px;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border: 2px solid $bsi-color-dark-lighter;
  background-color: $bsi-color-dark-lighter;
  transform: rotate(45deg);
}

.square.disabled {
  background-color: $bsi-white;
}

.square.coursePassed {
  background-color: $bsi-color-accent-green-base;
  border: 2px solid $bsi-color-accent-green-base;
}

.stripe {
  width: 0.5714rem;
  background-color: $bsi-color-dark-lighter;
}

.tile {
  flex-grow: 1;
  height: 5.3571rem;
  border: $bsi-color-light-dark 1px solid;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9286rem 1.1429rem 0.9286rem 0.5714rem;
  max-width: calc(100% - 2 * 1.1429rem - 0.5714rem);
  cursor: pointer;
  background-color: $bsi-color-light-dark;
}

.tile.selected {
  border-left: $bsi-color-primary-blue-base 0.5714rem solid;
  background-color: $bsi-color-primary-blue-lighter;
}

.title {
  display: flex;
  flex-grow: 1;
  font-size: 1.2857rem;
  font-weight: bold;
  margin-left: 0.8571rem;
}

:global(.icon).dropdownIcon {
  font-size: 1.3rem;
}

.hidden {
  display: none;
}

@media screen and (max-width: 809px) {
  .tile {
    width: 80vw;
  }
}
