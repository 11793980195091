@import "../../../../../index.scss";

.root {
  flex: 1;
  overflow-y: auto;
}

.questions {
  overflow-y: auto;
  height: calc(100% - $assessment-footer-height);
}

.question {
  padding: 1.8571rem 0 0 1.4286rem;
  display: flex;
}

.questions .question > i {
  font-size: 1.1429rem;
  margin: 0.1429rem 0.7143rem 0 0;
}

.green {
  color: $bsi-color-accent-green-base;
}

.red {
  color: $bsi-color-accent-red-base;
}
