.bubble {
  display: block;
  position: absolute;
  top: 0;
  right: 1rem;
  max-width: 30rem;
  padding: 20px;
  border: none;
  border-radius: 5px;
  background: white;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14);
  z-index: 1;
  transform: translateX(200%);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  animation: flyIn 0.5s ease-out;
}

.visible {
  animation: flyIn 0.5s ease-out forwards;
}

.hidden {
  animation: flyOut 0.5s ease-out;
}

@keyframes flyIn {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes flyOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
