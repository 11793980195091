@import "../../../../../index.scss";

.root {
  padding: 1.2857rem 2.1429rem;
  box-shadow: 3px 0 6px 0 rgba($bsi-black, 0.16);
  border-top: solid 1px $bsi-color-light-darker;
  width: 120%;
  background-color: $bsi-white;
}

.root > button:not(:first-child) {
  margin-left: 1.0714rem;
}

@media (max-width: 809px) {
  .root {
    margin-left: -1.1429rem;
  }

  .root > button {
    width: 7rem;
  }
}
