@import "../../../../../index.scss";

.root {
  display: flex;
}

.checkmark {
  display: flex;
  width: 2.2857rem;
  height: 5.3571rem;
  align-items: center;
  position: relative;
}

.checkmark button {
  background: $bsi-white;
}

.halfHeightCheckmark {
  height: 2.1875rem;
}

.halfHeightCheckmark > div {
  height: 125%;
  bottom: 75%;
}

.buttonIcon {
  z-index: 1;
  line-height: 0;
  /* Increases the check circle size */
  font-size: 1.1429rem;
}

.buttonIcon.disabled,
.buttonIcon.selected {
  width: 1.1429rem;
  height: 1.1429rem;
  border-radius: 50%;
}

.buttonIcon.disabled {
  border: solid 2px $bsi-color-light-darker;
  background-color: $bsi-white;
}

.buttonIcon.selected {
  border: solid 1px $bsi-color-primary-blue-base;
  background-color: $bsi-color-primary-blue-base;
}

.buttonIcon i {
  margin: -1px 0 0 -2px;
  font-size: 1.1429rem;
  color: $bsi-color-accent-green-base;
}

.buttonIconRed i {
  margin: -1px 0 0 -2px;
  font-size: 1.1429rem;
  color: $bsi-color-accent-red-base;
}

.stripe {
  width: 0.5714rem;
  background-color: $bsi-color-light-dark;
}

.stripe.selected {
  background-color: $bsi-color-primary-blue-base;
}

.stripe.selected.error {
  background-color: $bsi-color-accent-red-base;
}

.tile {
  flex-grow: 1;
  height: 5.3571rem;
  border: $bsi-color-light-dark 1px solid;
  border-left: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.9286rem 1.1429rem 0.9286rem 0.5714rem;
  max-width: calc(100% - 2 * 1.1429rem - 0.5714rem);
  cursor: pointer;
}

.halfHeightTile {
  height: 2.1875rem;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.tile.selected {
  background-color: rgba($bsi-color-primary-blue-base, 0.1);
}

.disabled .title,
.disabled .meta {
  opacity: 0.5;
}

.title {
  display: flex;
  font-size: 1.2rem;
  align-items: center;
}

.title .icon {
  color: $bsi-color-dark-light;
}

.selected .title .icon {
  color: $bsi-color-primary-blue-base;
}

.iconRed {
  margin-left: 0.1rem;
  margin-right: 0.3rem;
  color: $bsi-color-accent-red-dark;
}

.selected .iconRed {
  color: $bsi-color-accent-red-base;
}

.text {
  margin-left: 0.4286rem;
  font-size: 1rem;
  overflow-wrap: anywhere;
}

.selected .text {
  font-weight: bold;
  color: $bsi-color-primary-blue-base;
}

.error .text {
  color: $bsi-color-accent-red-dark;
}

.selected.error .text {
  color: $bsi-color-accent-red-base;
}

.meta {
  display: grid;
  justify-content: space-between;
  font-size: 0.8571rem;
  color: $bsi-color-dark-lighter;
  grid-template-columns: auto auto;
}

.meta .description {
  margin-left: 1rem;
  justify-self: end;
}

.meta .publisher {
  overflow-wrap: anywhere;
}

.hidden {
  display: none;
}

@media screen and (max-width: 809px) {
  .tile {
    width: 80vw;
  }
}
