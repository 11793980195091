@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 45.7rem;
  align-self: stretch;
}

.hidden {
  display: none;
}

.title {
  font-size: 1.4286rem;
  color: $bsi-color-dark-base;
  text-align: center;
  font-family: $bsi-gt-walsheim;
  font-weight: 900;
  line-height: 1.05;
  margin-bottom: 2.1429rem;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .root {
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    text-align: left;
  }

  .content {
    flex-direction: row;
  }
}

@media (max-width: 1079px) and (min-width: 810px) {
  .title {
    margin-bottom: 0.7143rem;
  }
}

@media (max-width: 809px) {
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    text-align: center;
  }
}
