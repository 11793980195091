@import "../../../../index.scss";

.circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: $bsi-color-dark-light;
  margin: 0 1px;
}

.circle svg {
  fill: $bsi-white;
  width: $circle-icon-width;
  height: 1rem;
  margin-left: calc(50% - $half-icon-width);
  vertical-align: middle;
}
