@import "../../../../index.scss";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 13.75rem;

  .title {
    margin: 3.063rem 0 1.813rem;
    font-size: 1.625rem;
  }

  .message {
    font-size: 1.125rem;
    color: $bsi-color-dark-light;
    white-space: pre-line;
    max-width: 30rem;
    text-align: center;
    line-height: 1.5;
    overflow-wrap: anywhere;

    a {
      color: $bsi-color-primary-blue-base;
      text-decoration: underline;
    }
  }

  .restart-course {
    button {
      display: flex;
      align-items: center;
      color: $bsi-color-primary-blue-base;
      text-decoration: underline;
    }
  }

  .discover-btn {
    margin: 2.375rem 0 1.375rem;

    button {
      width: 16.625rem;
    }
  }
}
