@import "../../../../index.scss";

.head {
  color: $bsi-color-dark-light;
  margin-bottom: 3.4286rem;
  font-size: 1.1429rem;
}

.flyoutContainer {
  overflow: hidden;
}

.mobileFilterHeader {
  display: flex;
  font-size: 1.1429rem;
  align-items: center;
  padding-top: 18px;
  padding-left: 1.7143rem;
  padding-right: 1.4286rem;
  padding-bottom: 15px;
  border-bottom: 1px solid $bsi-color-light-dark;
  margin-bottom: 15px;
  color: $bsi-color-dark-base;
}

.filterResults {
  padding-left: 0.5714rem;
  color: $bsi-color-dark-base;
}

.closeIconContainer {
  flex: 1;
  text-align: right;
  font-size: 1.1429rem;
}

.iconColor {
  color: $bsi-color-dark-light;
}

i:global(.icon).heavy {
  font-weight: bold;
}

.extraPadding {
  margin-left: 1.7143rem;
  margin-right: 0.7857rem;
}

.content {
  height: 92%;
  overflow-y: auto;
}

.contentContainer {
  height: 100%;
  /* Provides offset for scrollbar */
  margin-right: 0.5rem;
}

.filterSectionContainer {
  padding-bottom: 1.5714rem;
  border-bottom: 1px solid $bsi-color-light-dark;
  margin-bottom: 1.7143rem;
  padding-left: 1px;
  overflow-x: hidden;
}

@media (max-width: 809px) {
  .content {
    height: 85%;
  }
}
