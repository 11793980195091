@import "../../../../index.scss";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  margin-top: 1.5rem;
  overflow-y: auto;
}

.eventInfo > :not(:last-child) {
  margin-bottom: 1.5rem;
}

.root > :not(:last-child) {
  margin-bottom: 1.5rem;
}

.eventInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.emptyStateIcon {
  width: 12.86rem;
  min-height: 10.29rem;
}

.notStarted {
  margin-top: 0;
  max-width: 40.43rem;
  align-self: center;

  & > h1 {
    font-weight: 800;
    margin-top: 1.64rem;
    margin-bottom: 1.57rem;
  }

  & > span {
    font-size: 1.29rem;
    margin-bottom: 7.14rem;
  }
}

.restricted {
  flex-grow: 1;
  align-self: center;
}

.timeRange {
  font-weight: bold;
  display: inline;
}

.moreSessions {
  padding-top: 1.93rem;
  padding-bottom: 0.57rem;
  color: #9c989e;
}

.moreSessionsButton {
  color: $bsi-white;
  font-weight: bold;
  background-color: $bsi-color-primary-blue-base;
  margin-top: 2rem;
  padding: 0.79rem 3.36rem;
  white-space: nowrap;
  border-radius: 0.2857rem;
  border: none;
  cursor: pointer;
  font-size: 1.1429rem;
  letter-spacing: -0.07rem;
}
