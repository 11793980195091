@import "../../../../index.scss";

.root {
  position: relative;
  height: 26.2857rem;
  margin: 0rem auto;
  overflow: hidden;
  max-width: 1440px;
}

.root.hidden {
  display: none;
}

.img {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 368px;
  object-fit: cover;
  object-position: center;
  filter: blur(0.4286rem);
  transition: opacity 2s;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $bsi-black;
  opacity: 0.7;

  /* Time here should match the animationDuration in the definitions */
  transition: opacity linear 500ms;
}

.darkened {
  opacity: 0.9;
}

.container {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $bsi-white;
  z-index: 1;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.7857rem 4.5rem 1.7857rem 3.9286rem;
}

.title {
  font-weight: bold;
  font-size: 1.4286rem;
  font-family: $bsi-gt-walsheim;
  letter-spacing: 0.7px;
  line-height: 1;
}

.right {
  flex: 1;
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.1429rem;
  color: $bsi-color-light-dark;
}

.count {
  color: $bsi-white;
  opacity: 0.8;
  padding-right: 0.3571rem;
  font-size: 1rem;
  white-space: nowrap;
}

.iconColor {
  color: $bsi-color-light-dark;
}

.leftIcon {
  padding-left: 1.2857rem;
}

.rightIcon {
  padding-left: 2.0714rem;
  padding-right: 0.6429rem;
}

.body {
  flex: 1;
  display: block;
}

@keyframes fade-out {
  0% {
    opacity: 100;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    display: inherit;
  }

  100% {
    opacity: 1;
  }
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .root {
    width: 100%;
    height: auto;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .header {
    padding: 1.6429rem 0.8571rem 1.0714rem 1.2857rem;
  }

  .leftIcon {
    padding-left: 0.7143rem;
  }

  .rightIcon {
    padding-right: 0.5rem;
    padding-left: 2.1429rem;
  }
}

/* Mobile breakpoint */
@media (max-width: 809px) {
  .title {
    font-size: 1.2857rem;
  }

  .header {
    padding: 1.7857rem 0.5rem 2.1429rem 1.1429rem;
  }

  .rightIcon {
    padding-left: 2.1429rem;
  }

  .leftIcon {
    padding-left: 0.7143rem;
  }
}
