@import "../../../../index.scss";

.flexGrid {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}

.mainContent {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $bsi-color-light-dark;
  flex: 1;
  min-height: 0;
}

.filterBarContainer {
  position: static;
  padding-top: 1.6429rem;
  padding-right: 1.1429rem;
  flex-basis: 20.3571rem;
  box-sizing: content-box;
  overflow-y: auto;
}

.flowList {
  padding-top: 1.1429rem;
  flex: 1;
  overflow-y: auto;
  padding-right: 1rem;
  margin-right: -0.7143rem;
}

.leftPadding {
  padding-left: 2rem;
  border-left: 1px solid $bsi-color-light-dark;
  margin-left: 0.5rem;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .root {
    padding: 2rem;
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .root {
    padding: 1.1429rem;
  }

  .mainContent {
    flex-direction: column;
  }

  .filterBarContainer {
    position: absolute;
    border-right: none;
    padding: 0;
  }

  .flowList {
    padding-top: 1.6429rem;
    padding-right: 0.5rem;
  }

  .leftPadding {
    padding-left: 0;
    border-left: none;
    margin-left: 0;
  }
}

@media (max-width: 809px) {
  .header {
    border-bottom: none;
  }
}
