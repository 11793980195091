@import "../../../../index.scss";

.container {
  font-size: 1.2857rem;
  margin-left: 1.1429rem;
  color: $bsi-color-dark-light;
}

.sortLabel {
  font-size: 1.1429rem;
  margin-left: 0.2143rem;
  color: $bsi-color-dark-base;
}
