@import "../../../../index.scss";

.root {
  padding: $page-padding;
  margin: 0 auto;
  box-sizing: content-box;
  width: calc(100% - 2 * $page-wrapper-padding);
  max-width: $large-screen-page-wrapper-width;
  /* Page padding top */
  flex-grow: 1;
  overflow: hidden;
}

/* tablet-landscape breakpoint */
@media (max-width: 1439px) {
  .root {
    padding: $page-wrapper-padding $page-wrapper-padding 0 $page-wrapper-padding;
    height: calc(100vh - $app-header-height - $page-wrapper-padding);
  }
}

/* tablet-portrait breakpoint */
@media (max-width: 1079px) {
  .root {
    padding: 1.1429rem 1.1429rem 0 1.1429rem;
    height: calc(100vh - $app-header-height - 1.1429rem);
    width: calc(100% - 2 * 1.1429rem);
  }
}
