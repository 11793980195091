@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
}

.requestDataButton {
  line-height: 1.4286rem;
  padding: 0.7143rem 2.2857rem;
  margin-top: 1.7143rem;
  width: 13.5714rem;

  &:disabled {
    pointer-events: none;
  }
}

.privacyText,
.requestDataText {
  width: 50%;
  color: $bsi-color-dark-base;
}

.privacyText {
  margin-bottom: 4rem;
}

.requestDataText {
  margin: 0;
}

:global(.ui.progress) {
  margin: 0 !important;
}

:global(.ui.progress > .bar) {
  height: 0.5714rem !important;
}

.warning {
  width: 25%;
}

.progressWrapper {
  display: flex;
  align-items: center;

  i {
    font-size: 1rem;
    margin-left: 1rem;
    color: $bsi-color-dark-lighter;
  }
}

.downloadLink {
  display: flex;
  color: $bsi-color-primary-blue-base;
  border: none;
  background: none;
  margin-top: 1.7143rem;
}

.error {
  margin-top: 1.7143rem;
  color: $bsi-color-accent-red-base;
}

.heading {
  margin-bottom: 1.7143rem;
  text-transform: uppercase;
  color: $bsi-color-dark-base;
  font-family: $bsi-gotham-s-sm;
  font-size: 1.1429rem;
}

@media (max-width: 1079px) {
  .privacyText,
  .requestDataText {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .requestDataButton {
    width: 100%;
    padding: 0.7143rem 0;
  }
}
