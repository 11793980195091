@import "../../../../../index.scss";

.questionTitle {
  padding: 0 0 1.7143rem 0;
  font-size: 1.4286rem;
  font-weight: bold;
  line-height: 1.05;
  color: $bsi-color-dark-base;
}

.answers {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;

  label {
    font-size: 1.1429rem !important;
    margin-bottom: 1.1429rem;
  }
}

.item:global(.ui) label {
  font-size: 1.1429rem;
}

.item:global(.ui) label::before {
  border-radius: 2px;
}

.text {
  outline: none;
  padding: 0.7143rem;
  width: 477px;
  border-radius: 4px;
  border: solid 1px $bsi-color-light-dark;
}

@media (max-width: 809px) {
  .text {
    width: 100%;
    margin: 0 0 0.7143rem;
    resize: none;
  }

  .item {
    margin-bottom: 0.7143rem;
    padding: 0;
  }

  .answers {
    padding: 0.7143rem;
  }
}
