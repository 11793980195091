@import "../../../../index.scss";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  flex-grow: 1;
}

.spaced {
  padding-top: 1.4286rem;
}

.loadingContainer {
  height: 5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.eventInfo {
  margin-bottom: 2rem;
}

@media (max-width: 809px) {
  .eventInfo {
    margin-bottom: 0.9375rem;
  }

  .content {
    padding: 0 0.625rem;
  }

  .flyoutRegistration {
    z-index: 10000;
  }

  .flyoutTopBar {
    height: 0.8125rem;
    width: 100%;
    background-color: $bsi-color-accent-green-base;
  }

  .flyoutMessage {
    margin: auto;
    display: flex;
    gap: 0.75rem;
    width: 75%;
  }

  .flyoutMessage > div {
    display: flex;
    margin: auto;
  }
}
