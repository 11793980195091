@import "../../../../../index.scss";

.title {
  /* font size are changing based on container font size */
  font-size: 1.4em;
  font-family: $bsi-gotham-s-sm;
  margin: 0;
  margin-top: 0.9rem;
  overflow-wrap: anywhere;
  display: inline;
  color: $bsi-color-dark-base;

  &.interactive {
    &:hover {
      text-decoration: underline;
      color: $bsi-color-primary-blue-base;
      cursor: pointer;
    }
  }
}

.description {
  /* along with the title this is the only part that is changing (based on current designs) */
  font-size: 1.1em;
  line-height: 1.56;
  color: $bsi-color-dark-light;
  margin-top: 0.2rem;
  height: 3.3rem;
  flex-grow: 1;
  overflow-wrap: anywhere;
}

/* last-child overwrites the semantic style of no margin-bottom */
p.marginBottom:last-child {
  margin-bottom: 1.3rem;
}

.link {
  display: inline;
  text-decoration: none;
  color: $bsi-color-dark-base;
}

.link:hover {
  text-decoration: underline;
  color: $bsi-color-primary-blue-base;
}
