@import "../../../../../index.scss";

.segment {
  margin-right: 2em;
  color: rgba($bsi-color-dark-base, 0.5);
  padding-bottom: 0.635em;
}

.activeSegment {
  padding-bottom: 0;
  color: $bsi-color-dark-base;
  z-index: 10;
}

.activeSegment:after {
  content: "";
  display: block;
  width: 2.5em;
  height: 2px;
  background-color: $bsi-color-primary-blue-base;
  margin: 0.635em auto 0 auto;
}

.disabled {
  color: $bsi-color-dark-lighter;
}
