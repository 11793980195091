@import "../../../../../index.scss";

.questionTitle {
  padding: 0 0 1.1429rem 0;
  font-size: 1.4286rem;
  font-weight: bold;
  line-height: 1.05;
  color: $bsi-color-dark-base;
}

.answers {
  display: flex;
  flex-direction: column;
}

.answer i {
  margin-right: 0.6429rem;
  font-size: 1.1429rem;
  color: $bsi-color-accent-green-base;
}

.correct {
  font-weight: 300;
  color: $bsi-color-accent-green-base;
}

.item {
  padding: 0.7143rem 1.7143rem;
}

.answers .answer .item label {
  font-size: 1.1429rem;
}

.answers .answer .item label::before {
  border-radius: 2px;
}

.hidden {
  visibility: hidden;
}
