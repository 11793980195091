@import "../../../../../index.scss";

.root {
  padding: 2.2857rem;
  line-height: 1.5;

  .heading {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.7143rem;

    h2 {
      margin: 0;
      font-weight: bold;
      font-family: $bsi-gt-walsheim;
    }

    // Targetting icon
    svg {
      cursor: pointer;
      color: $bsi-color-dark-light;
    }
  }

  hr {
    margin: 20px 0;
  }

  .loadContainer {
    position: relative;
    // Provide some space for the loader to fill
    height: 10rem;
  }

  .admin {
    display: flex;
    flex-wrap: wrap;
    gap: 0.7143rem;
    padding: 10px 0;

    .info {
      font-weight: bold;
    }
  }

  .centeredError {
    display: flex;
    gap: 1rem;
    align-items: center;

    .icon {
      color: $bsi-color-accent-yellow-base;
      font-size: 1.4286rem;
    }
  }

  > span {
    display: block;
    padding: 20px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
